const sharedInternalLinkFields = `
  typename: __typename
  ... on Model {
    slug: _slug
  }
`;

export const corporateInternalLinkFields = `
  ${sharedInternalLinkFields}
`;

export const jobsInternalLinkFields = `
  ${sharedInternalLinkFields}
`;
