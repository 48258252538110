import gql from 'graphql-tag';

const getTextSectionFragment = ({ ctaFields }) => gql`
  fragment textSectionFields on TextSection {
    typename: __typename
    id: _id
    title
    subtitle
    text
    cta {
      ${ctaFields}
    }
    alignment
    backgroundColor: background_color {
      color
    }
  }
`;

export default getTextSectionFragment;
