import gql from 'graphql-tag';

const LOCATION_DETAILS_QUERY = gql`
  query LOCATION_DETAILS_QUERY($afasCode: String) {
    LocationPages(where: { afas_code: $afasCode }) {
      items {
        id: _id
        afasCode: afas_code
        uspsTitle: usps_title
        usps
        address
        postal
        city
        phone
      }
    }
  }
`;

export default LOCATION_DETAILS_QUERY;
