import gql from 'graphql-tag';

const getDownloadSectionFragment = ({ ctaFields }) => gql`
  fragment downloadSectionFields on DownloadSection {
    typename: __typename
    id: _id
    title
    text
    cta {
      ${ctaFields}
    }
    backgroundColor: background_color {
      color
    }
  }
`;

export default getDownloadSectionFragment;
