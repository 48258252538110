import jobAlertPageFragment from '@lib/fragments/jobAlertPage';
import gql from 'graphql-tag';

const JOB_ALERT_PAGE_QUERY = gql`
  query JOB_ALERT_PAGE_QUERY($locale: String) {
    JobAlertPage(locale: $locale) {
      ...jobAlertFields
    }
  }
  ${jobAlertPageFragment}
`;

export default JOB_ALERT_PAGE_QUERY;
