import Button from '@atoms/Button/Button';
import NavigationItem from '@atoms/NavigationItem/NavigationItem';
import { mainMenuLinkType } from '@lib/proptypes';
import useFocusTrap from '@utils/useFocusTrap';
import useStore from '@utils/useStore';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import styles from './Menu.module.scss';
import MenuToggle from './MenuToggle';
import SubMenu from './SubMenu';

function Menu({
  mainMenu,
  menuRef,
  menuIsShown,
  MENU_ID,
  toggleMenu,
  shownSubmenuId,
  toggleSubMenu,
}) {
  const menuToggleRef = useRef();
  const { enableFocusTrap, disableFocusTrap } = useFocusTrap(
    menuRef,
    menuToggleRef
  );

  const {
    store: { isJobsDomain },
  } = useStore();

  useEffect(() => {
    if (menuIsShown) {
      enableFocusTrap();
    } else {
      disableFocusTrap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuIsShown]);

  return (
    <div
      id={MENU_ID}
      className={classNames(styles.menu, menuIsShown && styles.menuIsShown)}
    >
      <div ref={menuRef} className={styles.container}>
        <MenuToggle
          MENU_ID={MENU_ID}
          onClick={toggleMenu}
          menuIsShown={menuIsShown}
          classModifier="cross"
          extraClasses={styles.closeMenu}
          menuToggleRef={menuToggleRef}
          isCloseButton
        />

        <nav className={styles.nav}>
          <ul className={classNames(styles.list, 'u-list-clean')}>
            {mainMenu.map(menuItem => (
              <li key={menuItem.id}>
                {menuItem.children.length ? (
                  <SubMenu
                    menuItem={menuItem}
                    shownSubmenuId={shownSubmenuId}
                    toggleSubMenu={toggleSubMenu}
                  />
                ) : (
                  <NavigationItem item={menuItem} extraClasses="u-menu-link" />
                )}
              </li>
            ))}

            {isJobsDomain && (
              <li>
                <Button
                  label="Past Vitalis bij jou?"
                  href="/matchmaker"
                  size="small"
                  extraClasses={styles.matchMakerButton}
                />
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
}

Menu.propTypes = {
  mainMenu: PropTypes.arrayOf(mainMenuLinkType),
  MENU_ID: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  menuRef: PropTypes.object.isRequired,
  menuIsShown: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  shownSubmenuId: PropTypes.string,
  toggleSubMenu: PropTypes.func.isRequired,
};

export default Menu;
