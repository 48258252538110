import gql from 'graphql-tag';

const sharedPageTypes = `
  "HomePage"
  "ContentPage"
  "NewsOverviewPage"
  "NewsPage"
  "AgendaOverviewPage"
  "LocationOverviewPage"
  "LocationPage"
`;

const corporatePageTypes = `
  ${sharedPageTypes}
  "ReferralPage"
`;

const jobsPageTypes = `
  ${sharedPageTypes}
  "MatchmakerPage"
  "StoryOverviewPage"
  "StoryPage"
  "VacancyOverviewPage"
  "JobAlertPage"
`;

export const getPageTypes = ({ isCorporateDomain }) =>
  isCorporateDomain ? corporatePageTypes : jobsPageTypes;

export const getAllSlugsTotalQuery = ({ isCorporateDomain }) => {
  const pageTypeNames = getPageTypes({ isCorporateDomain });

  return gql`
      query ALL_SLUGS_TOTAL_QUERY($untilDate: String!) {
        ContentItems(where: {
          _typename_any: [
            ${pageTypeNames}
          ]
        }) {
          total
        }
        EventPages(
          where: {
            end_date_gte: $untilDate
          }
        ) {
          total
        }
      }
    `;
};

const getAllSlugsQuery = ({ isCorporateDomain }) => {
  const pageTypeNames = getPageTypes({ isCorporateDomain });

  return gql`
    query ALL_SLUGS_QUERY($untilDate: String!, $limit: Int!, $skip: Int!) {
      ContentItems(
        limit: $limit
        skip: $skip
        where: {
          _typename_any: [
            ${pageTypeNames}
          ]
        }
      ) {
        items {
          ... on Model {
            typename: __typename
            slug: _slug
          }
        }
      }
      EventPages(
        limit: $limit
        skip: $skip
        where: {
          end_date_gte: $untilDate
        }
      ) {
        items {
          typename: __typename
          slug: _slug
        }
      }
    }
  `;
};

export default getAllSlugsQuery;
