import { storyItemFragment } from '@lib/fragments';
import {
  jobsSectionsFields,
  jobsSectionsFragment,
} from '@lib/fragments/sections';
import { seoFields } from '@lib/fragments/seo';
import gql from 'graphql-tag';

const STORY_PAGE_QUERY = gql`
  query STORY_PAGE_QUERY($slug: String, $locale: String) {
    StoryPage(slug: $slug, locale: $locale) {
      ...storyItemFields
      ${jobsSectionsFields}
      ${seoFields}
    }
  }
  ${storyItemFragment}
  ${jobsSectionsFragment}
`;

export default STORY_PAGE_QUERY;
