import { eventItemFragment } from '@lib/fragments';
import gql from 'graphql-tag';

const AGENDA_IFRAME_QUERY = gql`
  query AGENDA_IFRAME_QUERY($location: [String], $untilDate: String) {
    EventPages(
      limit: 3
      skip: 0
      locale: "nl-NL"
      sort: end_date_ASC
      where: { location: { _slug_any: $location }, end_date_gte: $untilDate }
    ) {
      items {
        ...eventItemFields
      }
    }
  }
  ${eventItemFragment}
`;

export default AGENDA_IFRAME_QUERY;
