import gql from 'graphql-tag';

import { eventPageFragment } from '../fragments';

const RELATED_EVENTS_QUERY = gql`
  query RELATED_EVENTS_QUERY(
    $id: [String]
    $untilDate: String
    $locale: String
    $highlightedOnly: Boolean
    $locations: [String]
  ) {
    events: EventPages(
      limit: 3
      where: {
        end_date_gte: $untilDate
        _id_nany: $id
        highlighted_event: $highlightedOnly
        location: { _id_any: $locations }
      }
      sort: end_date_ASC
      locale: $locale
    ) {
      typename: __typename
      items {
        ...eventFields
      }
    }
  }
  ${eventPageFragment}
`;

export default RELATED_EVENTS_QUERY;
