import { assetFragment } from '@lib/fragments';
import gql from 'graphql-tag';

const getTextImageSectionFragment = ({ ctaFields }) => gql`
  fragment textImageSectionFields on TextImageSection {
    typename: __typename
    id: _id
    title
    subtitle
    text
    cta {
      ${ctaFields}
    }
    imageAlt: alt_text
    image {
      url(width: 1920, format: "webp")
      placeholder: url(width: 8)
      ...assetFields
    }
    showImageAsBlob: show_image_as_blob
    textLeft: text_left
    backgroundColor: background_color {
      color
    }
  }
  ${assetFragment}
`;

export default getTextImageSectionFragment;
