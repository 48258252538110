import PropTypes from 'prop-types';

import ctaType from './ctaType';
import imageType from './imageType';
import seoTypes from './seoTypes';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
  isWooninc: PropTypes.bool.isRequired,
  address: PropTypes.string.isRequired,
  postal: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  phone: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  image: imageType,
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  widget: PropTypes.string,
  widgetTitle: PropTypes.string,
  widgetText: PropTypes.string,
  widgetButton: ctaType,
  afasCode: PropTypes.string,
  uspsTitle: PropTypes.string,
  usps: PropTypes.string,
  ...seoTypes,
});
