import gql from 'graphql-tag';

import { newsPageFragment } from '../fragments';

const RELATED_NEWS_QUERY = gql`
  query RELATED_NEWS_QUERY(
    $id: [String]
    $categories: [String]
    $locale: String
  ) {
    SameCategoryNews: NewsPages(
      limit: 3
      where: { categories: { _id_any: $categories }, _id_nany: $id }
      sort: publish_on_DESC
      locale: $locale
    ) {
      typename: __typename
      items {
        ...newsFields
      }
    }
    UpcomingNews: NewsPages(
      limit: 3
      where: { _id_nany: $id }
      sort: publish_on_DESC
      locale: $locale
    ) {
      typename: __typename
      items {
        ...newsFields
      }
    }
  }
  ${newsPageFragment}
`;

export default RELATED_NEWS_QUERY;
