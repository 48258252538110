import gql from 'graphql-tag';

import assetFragment from './asset';

const storyItemFragment = gql`
  fragment storyItemFields on StoryPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    text
    image {
      ...assetFields
      url(width: 3080, format: "webp")
      placeholder: url(width: 8)
    }
    imageAlt: alt_text
    fieldsOfWork: fields_of_work {
      id: _id
      label: title
    }
  }
  ${assetFragment}
`;

export default storyItemFragment;
