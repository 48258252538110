import { eventItemFragment } from '@lib/fragments';
import { seoFields } from '@lib/fragments/seo';
import gql from 'graphql-tag';

const AGENDA_OVERVIEW_PAGE_QUERY = gql`
  query AGENDA_OVERVIEW_PAGE_QUERY(
    $limit: Int
    $skip: Int
    $locale: String
    $categories: [String]
    $locations: [String]
    $fromDate: String
    $untilDate: String
    $untilDateHighlighted: String
  ) {
    AgendaOverviewPage(locale: $locale) {
      typename: __typename
      id: _id
      title
      ${seoFields}
    }
    EventPages(
      limit: $limit
      skip: $skip
      locale: $locale
      where: {
        categories: { _id_any: $categories  }
        location: { _id_any: $locations  }
        start_date_lte: $fromDate,
        end_date_gte: $untilDate,
        highlighted_event: false,
      }
      sort: end_date_ASC
    ) {
      total
      items {
        ...eventItemFields
      }
    }
    HighlightedEventPages: EventPages(
      limit: $limit    
      locale: $locale
      where: {
        highlighted_event: true,
        end_date_gte: $untilDateHighlighted,
      }
      sort: end_date_ASC
    ) {
      total
      items {
        ...eventItemFields
      }
    }
    EventCategories(limit: 50) {
      items {
        id: _id
        label
      }
	  }
    LocationPages(limit: 50) {
      items {
        id: _id
        title
      }
	  }
  }
  ${eventItemFragment}
`;

export default AGENDA_OVERVIEW_PAGE_QUERY;
