import getFaqItemFragment from '@molecules/FaqList/faq-item-fragment';
import gql from 'graphql-tag';

const getFaqSectionFragment = ({ ctaFields }) => gql`
  fragment faqSectionFields on FaqSection {
    typename: __typename
    id: _id
    title
    subtitle
    text
    cta {
      ${ctaFields}
    }
    faqList: faq_list {
      ...faqItemFields
    }
    firstQuestionOpen: first_question_open
    backgroundColor: background_color {
      color
    }
  }
  ${getFaqItemFragment({ ctaFields })}
`;

export default getFaqSectionFragment;
