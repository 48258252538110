import { storyItemFragment } from '@lib/fragments/';
import gql from 'graphql-tag';

const STORY_PAGES_QUERY = gql`
  query STORY_PAGES_QUERY(
    $limit: Int
    $skip: Int
    $locale: String
    $fieldsOfWork: [String]
  ) {
    StoryPages(
      limit: $limit
      skip: $skip
      locale: $locale
      where: { fields_of_work: { _id_any: $fieldsOfWork } }
      sort: publish_on_DESC
    ) {
      typename: __typename
      total
      items {
        ...storyItemFields
      }
    }
  }
  ${storyItemFragment}
`;

export default STORY_PAGES_QUERY;
