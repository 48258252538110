import WrapInLink from '@atoms/Button/WrapInLink';
import AboutSVG from '@svg/about.svg';
import AgendaSVG from '@svg/agenda.svg';
import CompensationSVG from '@svg/compensation.svg';
import HomeCareSVG from '@svg/homecare.svg';
import InnovationSVG from '@svg/innovation.svg';
import LivingSVG from '@svg/living.svg';
import RehabilitateSVG from '@svg/rehabilitate.svg';
import VisitSVG from '@svg/visit.svg';
import WorkSVG from '@svg/work.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './QuickLinks.module.scss';

const iconComponents = {
  homecare: HomeCareSVG,
  visit: VisitSVG,
  work: WorkSVG,
  about: AboutSVG,
  living: LivingSVG,
  rehabilitate: RehabilitateSVG,
  compensation: CompensationSVG,
  innovation: InnovationSVG,
  agenda: AgendaSVG,
};

function QuickLinks({ links }) {
  return (
    <ul className={classNames(styles.quickLinks, 'u-list-clean')}>
      {links.map((item, index) => {
        const Icon = iconComponents[item.icon.icon];
        const href =
          item.internalLink && item.internalLink[0]
            ? item.internalLink[0].slug
            : item.externalLink;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <li className={styles.item} key={`${item.tile}-${index}`}>
            <WrapInLink href={href} className={classNames(styles.link)}>
              <>
                {Icon && <Icon aria-hidden />}

                {item.title}
              </>
            </WrapInLink>
          </li>
        );
      })}
    </ul>
  );
}

QuickLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        icon: PropTypes.string,
      }),
      internalLink: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string,
        })
      ),
    })
  ),
};

export default QuickLinks;
