import gql from 'graphql-tag';

const typeformSectionFragment = gql`
  fragment typeformSectionFields on TypeformSection {
    typename: __typename
    id: _id
    typeform {
      id
    }
  }
`;

export default typeformSectionFragment;
