import newsFragment from '@lib/fragments/newsPage';
import gql from 'graphql-tag';

const NEWS_PAGES_QUERY = gql`
  query NEWS_PAGES_QUERY(
    $limit: Int
    $skip: Int
    $locale: String
    $categories: [String]
  ) {
    NewsPages(
      limit: $limit
      skip: $skip
      locale: $locale
      where: { categories: { _id_any: $categories } }
      sort: publish_on_DESC
    ) {
      typename: __typename
      total
      items {
        ...newsFields
      }
    }
  }
  ${newsFragment}
`;

export default NEWS_PAGES_QUERY;
