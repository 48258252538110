import gql from 'graphql-tag';

import {
  corporateInternalLinkFields,
  jobsInternalLinkFields,
} from './internalLinks';

const corporateMenuItemFields = `
  id: _id
  label
  internalLink: internal_link {
    ${corporateInternalLinkFields}
  }
  externalLink: external_link
`;

const jobsMenuItemFields = `
  id: _id
  label
  internalLink: internal_link {
    ${jobsInternalLinkFields}
  }
  externalLink: external_link
`;

export const corporateMenuItemFragment = gql`
  fragment corporateMenuItemFields on MenuItem {
    ${corporateMenuItemFields}
    children {
      ${corporateMenuItemFields}
      children {
        ${corporateMenuItemFields}
      }
    }
  }
`;

export const jobsMenuItemFragment = gql`
  fragment jobsMenuItemFields on MenuItem {
    ${jobsMenuItemFields}
    children {
      ${jobsMenuItemFields}
      children {
        ${jobsMenuItemFields}
      }
    }
  }
`;
