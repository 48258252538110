const translatedWeekDays = {
  monday: 'maandag',
  tuesday: 'dinsdag',
  wednesday: 'woensdag',
  thursday: 'donderdag',
  friday: 'vrijdag',
  saturday: 'zaterdag',
  sunday: 'zondag',
};

export default function getEventIntervalLabel({
  eventInterval,
  intervalDay,
  intervalTiming,
}) {
  if (eventInterval === 'daily') {
    return 'Dagelijks';
  }
  if (eventInterval === 'weekly') {
    return `Wekelijks
    ${intervalDay ? ` op ${translatedWeekDays[intervalDay]}` : ''}`;
  }
  if (eventInterval === 'monthly') {
    return `Maandelijks
    ${
      intervalDay
        ? ` op ${intervalTiming ? `de ${intervalTiming}e` : ''} ${
            translatedWeekDays[intervalDay]
          }`
        : ''
    }`;
  }
  return null;
}
