import { getRelatedNews } from '@lib/prepr';
import { useEffect, useState } from 'react';

import useStore from './useStore';

export default function useRelatedNews({ categories = [] }) {
  const {
    store: {
      host,
      preview,
      page: { id: pageId },
    },
  } = useStore();

  const [relatedNews, setRelatedNews] = useState([]);
  const [hasRelatedNews, setHasRelatedNews] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getRelatedNews({
        id: pageId,
        categories: categories.map(({ id }) => id),
        host,
        preview,
      });
      setRelatedNews(data);
      setHasRelatedNews(
        !!data.sameCategoryNews?.items?.length ||
          !!data.upcomingNews?.items?.length
      );
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { relatedNews, hasRelatedNews };
}
