import Button from '@atoms/Button/Button';
import Modal from '@molecules/Modal/Modal';
import QuickLinks from '@organisms/CorporateHome/QuickLinks';
import Icon from '@svg/quick-links-menu.svg';
import useStore from '@utils/useStore';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import styles from './QuickLinksModal.module.scss';

export default function QuickLinksModal() {
  const {
    store: {
      config: { quickMenuItems },
    },
  } = useStore();

  const router = useRouter();

  const [showMenu, setShowMenu] = useState(false);

  // Close menu when changing route
  useEffect(() => {
    setShowMenu(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  return (
    <>
      <Button
        label="Snelkeuze"
        Icon={Icon}
        modifierClass="quickLinksButton"
        extraClasses={styles.toggle}
        noAnimation
        onClick={() => setShowMenu(true)}
      />

      <Modal
        id="quick-links-modal"
        title="Welkom bij Vitalis"
        onClose={() => setShowMenu(false)}
        show={showMenu}
        modifierClass="quickLinksModal"
      >
        <p>Waar wilt u meer over weten?</p>

        <QuickLinks links={quickMenuItems} />
      </Modal>
    </>
  );
}
