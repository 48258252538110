import Button from '@atoms/Button/Button';
import CrossIcon from '@svg/cross.svg';
import PropTypes from 'prop-types';

import styles from './PreviewMessage.module.scss';

export default function PreviewMessage({ preview = false }) {
  if (!preview) return null;

  return (
    <Button
      Icon={CrossIcon}
      label="Preview modus sluiten"
      href="/api/exit-preview"
      extraClasses={styles.button}
      noTransition
      noAnimation
    />
  );
}

PreviewMessage.propTypes = {
  preview: PropTypes.bool,
};
