import gql from 'graphql-tag';

import assetFragment from './asset';

const locationPageFragment = gql`
  fragment locationFields on LocationPage {
    typename: __typename
    id: _id
    title
    intro
    slug: _slug
    isWooninc: is_wooninc
    address
    postal
    city
    phone
    categories {
      id: _id
      label
    }
    image {
      ...assetFields
      placeholder: url(width: 8)
      url(width: 960, height: 540, format: "webp")
    }
    afasCode: afas_code
    uspsTitle: usps_title
    usps
  }
  ${assetFragment}
`;

export default locationPageFragment;
