import { assetFragment } from '@lib/fragments';
import { abTestFields } from '@lib/fragments/abTestFragment';
import { jobsCtaFields } from '@lib/fragments/cta';
import getJobAlertBlockFields from '@lib/fragments/jobAlertBlock';
import getRecruiterBlockFields from '@lib/fragments/recruiterBlock';
import { seoFields } from '@lib/fragments/seo';
import gql from 'graphql-tag';

// DONT delete the assetFragment on line 33, this is needed for the getRecruiterBlockFields()
const VACANCY_OVERVIEW_PAGE_QUERY = gql`
  query VACANCY_OVERVIEW_PAGE_QUERY {
    VacancyOverviewPage {
      typename: __typename
      id: _id
      title
      jobAlert: job_alert {
        ${getJobAlertBlockFields({ ctaFields: jobsCtaFields })}
        ${abTestFields}
      }
      jobAlertSection: job_alert_section {
        typename: __typename
        id: _id
        recruiterBlock: block_one {
          ${getRecruiterBlockFields({ ctaFields: jobsCtaFields })}
        }
        jobAlertBlock: block_two {
          ${getJobAlertBlockFields({ ctaFields: jobsCtaFields })}
        }
        backgroundColor: background_color {
          color
        }
      }
      ${seoFields}
    }
  }
  ${assetFragment}
`;
// DONT delete the assetFragment above, this is needed for the getRecruiterBlockFields()

export default VACANCY_OVERVIEW_PAGE_QUERY;
