import {
  corporateCtaFields,
  jobsCtaFields,
  locationPageFragment,
} from '@lib/fragments';
import {
  getSectionsFields,
  getSectionsFragments,
} from '@lib/fragments/sections';
import { seoFields } from '@lib/fragments/seo';
import gql from 'graphql-tag';

const getlocationPageQuery = ({ isCorporateDomain }) => gql`
  query LOCATION_PAGE_QUERY($slug: String, $locale: String) {
    LocationPage(slug: $slug, locale: $locale) {
      ...locationFields
      widget
      widgetTitle: widget_title
      widgetText: widget_text
      widgetButton: widget_button {
        ${isCorporateDomain ? corporateCtaFields : jobsCtaFields}
      }
      ${seoFields}
      ${getSectionsFields({ isCorporateDomain })}
    }
  }
  ${locationPageFragment}
  ${getSectionsFragments({ isCorporateDomain })}
`;

export default getlocationPageQuery;
