import { ITEMS_PER_PAGE } from '@constants';
import { useRouter } from 'next/router';
import { useState } from 'react';

import scrollTo from './scrollTo';

export default function usePagination({
  total,
  limit = ITEMS_PER_PAGE,
  fetchMore,
  scrollToRef,
}) {
  const { query, ...router } = useRouter();
  const [currentPage, setCurrentPage] = useState(Number(query.page) || 1);
  const totalPages = Math.ceil(total / limit);
  const hasPrevPage = currentPage > 1;
  const hasNextPage = !!totalPages && totalPages !== currentPage;

  const handleScrollTo = () => {
    scrollTo({ scrollToRef });
  };

  const handleRoute = page => {
    router.push(
      {
        path: router.path,
        query: { ...query, page },
      },
      null,
      { shallow: true, scroll: false }
    );
  };

  const handlePrevPage = () => {
    const updatedCurrentPage = currentPage - 1;
    setCurrentPage(updatedCurrentPage);
    fetchMore({ ...query, page: updatedCurrentPage });
    handleRoute(updatedCurrentPage);
    handleScrollTo();
  };

  const handleNextPage = () => {
    const updatedCurrentPage = currentPage + 1;
    setCurrentPage(updatedCurrentPage);
    fetchMore({ ...query, page: updatedCurrentPage });
    handleRoute(updatedCurrentPage);
    handleScrollTo();
  };

  // Navigate directly to a specific page
  const handleGoToPage = page => {
    // Check if the page exists in the results
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      handleRoute(page);
      fetchMore({ ...query, page });
      return handleScrollTo();
    }
    return null;
  };

  // Reset internal pagination
  const resetPagination = () => {
    setCurrentPage(1);
  };

  return {
    currentPage,
    totalPages,
    hasPrevPage,
    hasNextPage,
    handlePrevPage,
    handleNextPage,
    handleGoToPage,
    resetPagination,
  };
}
