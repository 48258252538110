import gql from 'graphql-tag';

const getTextVideoSectionFragment = ({ ctaFields }) => gql`
  fragment textVideoSectionFields on TextVideoSection {
    typename: __typename
    id: _id
    title
    subtitle
    text
    cta {
      ${ctaFields}
    }
    video {
      embeddedVideo: embedded_video {
        playbackId: playback_id
      }
      youtubeId: youtube_id
      alt: alt_text
      thumbnail {
        ...assetFields
        url(width: 1280, height: 720, format: "webp")
        placeholder: url(width: 8)
      }
    }
    textLeft: text_left
    backgroundColor: background_color {
      color
    }
  }
`;

export default getTextVideoSectionFragment;
