import PropTypes from 'prop-types';

const seoTypes = {
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  ogImage: PropTypes.shape({
    url: PropTypes.string,
  }),
};

export default seoTypes;
