import gql from 'graphql-tag';

const bannerSectionFragment = gql`
  fragment bannerSectionFields on BannerSection {
    typename: __typename
    id: _id
    title
    subtitle
    showTitleFirst: show_title_first
  }
`;

export default bannerSectionFragment;
