import { assetFragment } from '@lib/fragments';
import getJobAlertBlockFields from '@lib/fragments/jobAlertBlock';
import getRecruiterBlockFields from '@lib/fragments/recruiterBlock';
import gql from 'graphql-tag';

// DONT delete the assetFragment on line 20, this is needed for the getJobAlertBlockFields()
const getJobAlertSectionFragment = ({ ctaFields }) => gql`
  fragment jobAlertSectionFields on JobAlertSection {
    typename: __typename
    id: _id
    recruiterBlock: block_one {
      ${getRecruiterBlockFields({ ctaFields })}
    }
    jobAlertBlock: block_two {
      ${getJobAlertBlockFields({ ctaFields })}
    }
    backgroundColor: background_color {
      color
    }
  }
  ${assetFragment}
`;
// DONT delete the assetFragment above, this is needed for the getJobAlertBlockFields()

export default getJobAlertSectionFragment;
