import PropTypes from 'prop-types';

import categoryType from './categoryType';
import eventPageOverviewType from './eventPageOverviewType';
import seoTypes from './seoTypes';

export default PropTypes.shape({
  title: PropTypes.string.isRequired,
  intro: PropTypes.string,
  items: PropTypes.arrayOf(eventPageOverviewType),
  total: PropTypes.number.isRequired,
  categories: PropTypes.arrayOf(categoryType),
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ),
  ...seoTypes,
});
