import gql from 'graphql-tag';

import { storyItemFragment } from '../fragments';

const RELATED_STORIES_QUERY = gql`
  query RELATED_STORIES_QUERY(
    $id: [String]
    $fieldsOfWork: [String]
    $locale: String
  ) {
    SameFieldsOfWorkStories: StoryPages(
      limit: 3
      where: { fields_of_work: { _id_any: $fieldsOfWork }, _id_nany: $id }
      sort: publish_on_DESC
      locale: $locale
    ) {
      typename: __typename
      items {
        ...storyItemFields
      }
    }
    UpcomingStories: StoryPages(
      limit: 3
      where: { _id_nany: $id }
      sort: publish_on_DESC
      locale: $locale
    ) {
      typename: __typename
      items {
        ...storyItemFields
      }
    }
  }
  ${storyItemFragment}
`;

export default RELATED_STORIES_QUERY;
