import { refType } from '@lib/proptypes';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './MenuToggle.module.scss';

function MenuToggle({
  MENU_ID,
  menuToggleRef,
  menuIsShown,
  onClick,
  extraClasses,
  classModifier,
  isCloseButton,
}) {
  return (
    <button
      type="button"
      className={classNames(
        styles.menuToggle,
        extraClasses && extraClasses,
        classModifier && styles[classModifier]
      )}
      onClick={onClick}
      aria-label={menuIsShown ? 'Sluit menu' : 'Open menu'}
      aria-expanded={menuIsShown}
      aria-haspopup
      aria-controls={MENU_ID}
      ref={menuToggleRef}
    >
      <div className={styles.iconWrapper}>
        <div className={styles.icon}>
          <span />
          <span />
          <span />
        </div>
      </div>
      <span className={isCloseButton ? `u-visually-hidden` : styles.label}>
        Menu
      </span>
    </button>
  );
}

MenuToggle.propTypes = {
  MENU_ID: PropTypes.string.isRequired,
  menuToggleRef: refType,
  menuIsShown: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  extraClasses: PropTypes.string,
  classModifier: PropTypes.string,
  isCloseButton: PropTypes.bool,
};

export default MenuToggle;
