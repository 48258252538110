import gql from 'graphql-tag';

import assetFragment from './asset';

const newsFragment = gql`
  fragment newsFields on NewsPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    text
    publishOn: _publish_on
    image {
      ...assetFields
      url(width: 3080, format: "webp")
      placeholder: url(width: 8)
    }
    imageAlt: alt_text
    categories {
      id: _id
      label
    }
  }
  ${assetFragment}
`;

export default newsFragment;
