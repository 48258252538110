export default function useBreadcrumbs(page) {
  if (
    !page?.title ||
    ['HomePage', 'MatchmakerPage', 'MatchmakerResultsPage'].includes(
      page.typename
    )
  )
    return { breadcrumbs: [] };

  const homePage = {
    title: 'Home',
    slug: '/',
  };

  if (page.typename === 'NewsOverviewPage') {
    return {
      breadcrumbs: [
        homePage,
        {
          title: 'Actueel',
          slug: '/actueel',
        },
      ],
    };
  }

  if (page.typename === 'AgendaOverviewPage') {
    return {
      breadcrumbs: [
        homePage,
        {
          title: 'Agenda',
          slug: '/agenda',
        },
      ],
    };
  }

  if (page.typename === 'LocationOverviewPage') {
    return {
      breadcrumbs: [
        homePage,
        {
          title: 'Locaties',
          slug: '/locaties',
        },
      ],
    };
  }

  if (page.typename === 'VacancyOverviewPage') {
    return {
      breadcrumbs: [
        homePage,
        {
          title: 'Vacatures',
          slug: '/vacatures',
        },
      ],
    };
  }

  if (page.typename === 'JobAlertPage') {
    return {
      breadcrumbs: [
        homePage,
        {
          title: 'Vacatures',
          slug: '/vacatures',
        },
        {
          title: 'Vacaturemelding',
          slug: '/vacaturemelding',
        },
      ],
    };
  }

  if (page.typename === 'StoryOverviewPage') {
    return {
      breadcrumbs: [
        homePage,
        {
          title: 'Verhalen',
          slug: '/verhalen',
        },
      ],
    };
  }

  let breadcrumbs = [];

  const setSlug = item => {
    // Check if the slug starts with '/' otherwise add a '/' ourselves
    const slug = item?.slug?.indexOf('/') === 0 ? item.slug : `/${item.slug}`;
    const baseUrls = {
      NewsPage: 'actueel',
    };
    return `${baseUrls[item.typename] ?? ''}${slug}`;
  };
  // The middle part of the breadcrumbs will be filled with the parent pages,
  // This is a function that will call itself until not longer needed.
  const setBreadcrumbItem = item => {
    // News has no parent page other than the news overviewpage
    if (item.typename === 'NewsPage') {
      breadcrumbs = [...breadcrumbs, { title: 'Actueel', slug: '/actueel' }];
      return;
    }
    if (item.typename === 'EventPage') {
      breadcrumbs = [...breadcrumbs, { title: 'Agenda', slug: '/agenda' }];
      return;
    }
    if (item.typename === 'LocationPage') {
      breadcrumbs = [...breadcrumbs, { title: 'Locaties', slug: '/locaties' }];
      return;
    }
    if (item.typename === 'VacancyPage') {
      breadcrumbs = [
        ...breadcrumbs,
        { title: 'Vacatures', slug: '/vacatures' },
      ];
      return;
    }
    if (item.typename === 'StoryPage') {
      breadcrumbs = [...breadcrumbs, { title: 'Verhalen', slug: '/verhalen' }];
    }
    // No more parent pages so we can stop calling itself
    if (!item.parentPage?.length) {
      return;
    }
    // Add the parent page to the breadcrumbs
    breadcrumbs = [
      { title: item.parentPage[0].title, slug: setSlug(item.parentPage[0]) },
      ...breadcrumbs,
    ];
    setBreadcrumbItem(...item.parentPage);
  };
  setBreadcrumbItem(page);
  // The first breadcrumb will always be home,
  // the last breadcrumb will always be the current page
  breadcrumbs = [
    homePage,
    ...breadcrumbs,
    {
      title: page.title,
      slug: setSlug(page),
    },
  ];

  return {
    breadcrumbs,
  };
}
