import ChevronRightIcon from '@svg/chevron-right.svg';
import { useBreadcrumbs, useStore } from '@utils';
import classNames from 'classnames';
import Link from 'next/link';

import styles from './Breadcrumbs.module.scss';

function Breadcrumbs() {
  const {
    store: { page },
  } = useStore();
  const { breadcrumbs } = useBreadcrumbs(page);

  if (!breadcrumbs.length) return null;

  return (
    <div className={styles.breadcrumbs}>
      <div className={styles.wrapper}>
        <nav
          className={classNames(styles.container, 'u-container-width')}
          aria-label="Breadcrumb"
        >
          <ol
            className={classNames(styles.list, 'u-list-clean')}
            itemScope
            itemType="https://schema.org/BreadcrumbList"
          >
            {breadcrumbs.map(({ title, slug }, index) => {
              const secondToLastPage = breadcrumbs.length - 1 === index + 1;
              const currentPage = breadcrumbs.length === index + 1;
              return (
                <li
                  itemScope
                  itemProp="itemListElement"
                  itemType="https://schema.org/ListItem"
                  key={slug}
                  className={classNames(
                    styles.listItem,
                    secondToLastPage && styles.secondToLastPage
                  )}
                >
                  <Link
                    href={slug}
                    className={classNames(
                      styles.link,
                      currentPage && styles.currentPage
                    )}
                    aria-current={currentPage ? 'page' : null}
                    itemProp="item"
                  >
                    <span className={styles.mobileLabel} aria-hidden>
                      Terug naar{' '}
                    </span>
                    <span itemProp="name">{title}</span>
                    <meta itemProp="position" content={index + 1} />
                  </Link>
                  {!currentPage && (
                    <ChevronRightIcon className={styles.seperator} />
                  )}
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
    </div>
  );
}

export default Breadcrumbs;
