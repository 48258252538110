import gql from 'graphql-tag';

const getFaqItemFragment = ({ ctaFields }) => gql`
  fragment faqItemFields on FaqItem {
    typename: __typename
    id: _id
    title
    text
    cta {
      ${ctaFields}
    }
  }
`;

export default getFaqItemFragment;
