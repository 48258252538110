import { storyItemFragment } from '@lib/fragments';
import { seoFields } from '@lib/fragments/seo';
import gql from 'graphql-tag';

const STORY_OVERVIEW_PAGE_QUERY = gql`
  query STORY_OVERVIEW_PAGE_QUERY(
    $limit: Int
    $skip: Int
    $locale: String
    $fieldsOfWork: [String]
  ) {
    StoryOverviewPage(locale: $locale) {
      typename: __typename
      id: _id
      title
      intro: intro_text
      ${seoFields}
    }
    StoryPages(
      limit: $limit
      skip: $skip
      locale: $locale,
      where: { fields_of_work: { _id_any: $fieldsOfWork } },
      sort: publish_on_DESC,
    ) {
      items {
        ...storyItemFields
      }
      total
    }
    FieldsOfWork {
      items {
        id: _id
        label: title
      }
    }
  }
  ${storyItemFragment}
`;

export default STORY_OVERVIEW_PAGE_QUERY;
