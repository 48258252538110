const getJobAlertBlockFields = ({ ctaFields }) => `
  typename: __typename
  id: _id
  title
  text
  firstCtaText: first_cta_text
  secondCta: second_cta {
    ${ctaFields}
  }
`;

export default getJobAlertBlockFields;
