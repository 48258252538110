import NavigationItem from '@atoms/NavigationItem/NavigationItem';
import ArrowLeftIcon from '@svg/arrow-left.svg';
import ChevronRightIcon from '@svg/chevron-right.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './SubMenu.module.scss';

function SubMenu({ menuItem, parentId, shownSubmenuId, toggleSubMenu }) {
  const { id } = menuItem;
  const subMenuIsShown = shownSubmenuId?.includes(id);
  const subMenuChildIsShown = shownSubmenuId?.includes('&');
  const SUB_MENU_TOGGLE_ID = `submenu-toggle-${id}`;
  const SUB_MENU_ID = `submenu-${id}`;
  const SUB_MENU_TITLE_ID = `submenu-title-${id}`;

  const handleToggleSubmenu = () => {
    if (subMenuIsShown) {
      toggleSubMenu({
        id: parentId,
      });
      return;
    }

    if (!subMenuIsShown && !parentId) {
      toggleSubMenu({
        id,
      });
      return;
    }
    if (!subMenuIsShown && parentId) {
      // When the submenu is a child of a submenu we want to keep the parent id,
      // this is done so the check on line 11 still returns true and a11y doens't break.
      toggleSubMenu({
        id: `${parentId}&${id}`,
      });
    }
  };

  return (
    <>
      <button
        type="button"
        id={SUB_MENU_TOGGLE_ID}
        className={classNames(
          parentId ? styles.subMenuLink : styles.toggle,
          'u-menu-link',
          subMenuChildIsShown && styles.subMenuChildIsShown
        )}
        onClick={handleToggleSubmenu}
        aria-expanded={subMenuIsShown}
        aria-haspopup
        aria-controls={SUB_MENU_ID}
      >
        {menuItem.label}
        <ChevronRightIcon className={styles.toggleIcon} />
      </button>
      <ul
        id={SUB_MENU_ID}
        aria-labelledby={SUB_MENU_TITLE_ID}
        className={classNames(
          styles.list,
          'u-list-clean',
          parentId && styles.nestedList,
          subMenuIsShown && styles.subMenuIsShown
        )}
      >
        <li className={styles.listItem}>
          <h3 id={SUB_MENU_TITLE_ID} className={styles.title}>
            {menuItem.label}
          </h3>
        </li>
        <li className={styles.listItem}>
          <button
            type="button"
            className={classNames(styles.backButton, 'u-menu-link')}
            onClick={handleToggleSubmenu}
            aria-label="Sluit sub-menu"
            aria-expanded={subMenuIsShown}
            aria-haspopup
            aria-controls={SUB_MENU_ID}
          >
            <ArrowLeftIcon />
            Terug
          </button>
        </li>
        <li className={styles.listItem}>
          <NavigationItem
            item={{
              ...menuItem,
              label: menuItem.label.replace('Over ', ''),
            }}
            extraClasses={classNames(
              styles.subMenuLink,
              styles.topSubMenuLink,
              parentId && styles.nestedSubMenuLink,
              'u-menu-link'
            )}
          />
        </li>
        {menuItem.children.map(subMenuItem => (
          <li className={styles.listItem} key={subMenuItem.id}>
            {subMenuItem.children?.length ? (
              <SubMenu
                menuItem={subMenuItem}
                parentId={id}
                shownSubmenuId={shownSubmenuId}
                toggleSubMenu={toggleSubMenu}
              />
            ) : (
              <NavigationItem
                item={subMenuItem}
                extraClasses={classNames(
                  styles.subMenuLink,
                  parentId && styles.nestedSubMenuLink,
                  'u-menu-link'
                )}
              />
            )}
          </li>
        ))}
      </ul>
    </>
  );
}

SubMenu.propTypes = {
  menuItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    internalLink: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        typename: PropTypes.string.isRequired,
      })
    ),
    externalLink: PropTypes.string,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        internalLink: PropTypes.arrayOf(
          PropTypes.shape({
            slug: PropTypes.string,
            typename: PropTypes.string.isRequired,
          })
        ),
        externalLink: PropTypes.string,
      })
    ),
  }).isRequired,
  parentId: PropTypes.string,
  shownSubmenuId: PropTypes.string,
  toggleSubMenu: PropTypes.func.isRequired,
};

export default SubMenu;
