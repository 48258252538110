import SearchIcon from '@svg/search.svg';
import useStore from '@utils/useStore';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './SearchToggle.module.scss';

function SearchToggle({ searchIsShown, toggleSearch, SEARCH_ID }) {
  const {
    store: { isCorporateDomain },
  } = useStore();

  return (
    <button
      type="button"
      className={classNames(
        styles.button,
        isCorporateDomain ? styles.corporateButton : styles.jobsButton
      )}
      onClick={toggleSearch}
      aria-label={searchIsShown ? 'Sluit zoekbalk' : 'Open zoekbalk'}
      aria-expanded={searchIsShown}
      aria-haspopup
      aria-controls={SEARCH_ID}
    >
      <SearchIcon className={styles.icon} />
      <span className={styles.label}>Zoeken</span>
    </button>
  );
}

SearchToggle.propTypes = {
  searchIsShown: PropTypes.bool.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  SEARCH_ID: PropTypes.string.isRequired,
};

export default SearchToggle;
