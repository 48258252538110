import { getRelatedEvents } from '@lib/prepr';
import { formatISO } from 'date-fns';
import { useEffect, useState } from 'react';

export default function useEventDetail(page) {
  const [relatedEvents, setRelatedEvents] = useState([]);
  const [hasRelatedEvents, setHasRelatedEvents] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getRelatedEvents({
        id: page.id,
        fromDate: formatISO(new Date()),
      });
      setRelatedEvents(data);
      setHasRelatedEvents(data.upcomingEvents?.items?.length);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { relatedEvents, hasRelatedEvents };
}
