import PropTypes from 'prop-types';

export default PropTypes.shape({
  hours: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  location: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      title: PropTypes.string,
      address: PropTypes.string,
      postel: PropTypes.string,
      city: PropTypes.string,
    }),
  ]),
});
