import PropTypes from 'prop-types';

import imageType from './imageType';
import locationType from './locationType';
import seoTypes from './seoTypes';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  text: PropTypes.string,
  image: imageType,
  imageAlt: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  highlightedMessage: PropTypes.string,
  highlightedEvent: PropTypes.bool,
  eventInterval: PropTypes.string,
  intervalDay: PropTypes.string,
  intervalTiming: PropTypes.number,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string,
  location: PropTypes.arrayOf(locationType).isRequired,
  contactName: PropTypes.string,
  contactEmail: PropTypes.string,
  contactPhone: PropTypes.string,
  ...seoTypes,
});
