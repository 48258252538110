import { eventItemFragment } from '@lib/fragments';
import gql from 'graphql-tag';

const EVENT_PAGES_QUERY = gql`
  query EVENT_PAGES_QUERY(
    $limit: Int
    $skip: Int
    $locale: String
    $categories: [String]
    $locations: [String]
    $fromDate: String
    $untilDate: String
  ) {
    EventPages(
      limit: $limit
      skip: $skip
      locale: $locale
      where: {
        categories: { _id_any: $categories }
        location: { _id_any: $locations }
        highlighted_event: false
        start_date_lte: $fromDate
        end_date_gte: $untilDate
      }
      sort: end_date_ASC
    ) {
      total
      items {
        ...eventItemFields
      }
    }
  }
  ${eventItemFragment}
`;

export default EVENT_PAGES_QUERY;
