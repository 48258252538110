import fetchApi from '@lib/fetchApi';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import getRandomInt from './getRandomInt';
import scrollTo from './scrollTo';
import useForm from './useForm';

export default function useReferralForm({ scrollToRef, signatureRef }) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { query, ...router } = useRouter();
  const [stage, setStage] = useState();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [signError, setSignError] = useState('');
  const progressBarInterval = useRef();
  const { inputs, handleChange } = useForm({
    gender: '',
    birthDate: '',
    name: '',
    address: '',
    zipcode: '',
    city: '',
    phoneNumber: '',
    emailAddress: '',
    citizenServiceNumber: '',
    healthInsurer: '',
    policyNumber: '',
    alreadyReceivingHomeCare: '',
    contactName: '',
    contactPhoneNumber: '',
    applicantName: '',
    applicantAddress: '',
    applicantZipcode: '',
    applicantPhoneNumber: '',
    applicantEmailAddress: '',
    applicantAgbCode: '',
    treatmentGroups: '',
    crisisOrUrgent: '',
    homeCare: '',
    wlzIndication: '',
    applicationNotes: '',
    medicalNotes: '',
    medication: '',
    notes: '',
    signature: '',

    /* Checks */
    isCompetent: false,
    clientApproved: false,
    termsAndConditions: false,
  });

  const formatZipcode = zipcode => {
    const trimmedZipcode = zipcode.replace(/\s/g, '');
    return `${trimmedZipcode.substring(0, 4)} ${trimmedZipcode.substring(4)}`;
  };

  // Fake upload progress bar animation
  const startProgressBar = init => {
    let prev = init;
    progressBarInterval.current = setInterval(() => {
      prev = prev < 80 ? (prev += getRandomInt({ min: 1, max: 3 })) : prev;
      setProgress(prev);
    }, 1000);
  };

  const resetProgressBarInterval = () => {
    clearInterval(progressBarInterval.current);
  };

  const clearState = () => {
    setLoading(false);
    setStage();
    resetProgressBarInterval();
  };

  const handleSuccess = () => {
    // Clear the progress bar interval
    resetProgressBarInterval();
    setProgress(100);
    setStage('Klaar, u wordt doorgestuurd naar de bedanktpagina');
    // minor timeout so the user can read the message above
    setTimeout(() => {
      router.push(`/verwijsformulier/bedankt`);
    }, 1000);
  };

  const handleError = e => {
    clearState();
    let message =
      'Er is iets fout gegaan bij het versturen van het verwijsformulier';

    if (e?.response?.data?.errorMessage) {
      message = `${message}: ${e.response.data.errorMessage}`;
    }

    setError({
      message,
    });

    setProgress(0);
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (!inputs.signature) {
      setSignError('Een handtekening is vereist');
      scrollTo({ scrollToRef: signatureRef });
      return;
    }

    // Check if the validation func exists
    if (!executeRecaptcha) {
      setError({
        message: 'Recaptcha kan niet worden geladen, probeer het opnieuw.',
      });
      return;
    }
    const token = await executeRecaptcha();
    // Check if we have a recaptcha token
    if (!token) {
      setError({
        message: 'Recaptcha kan geen token ophalen, probeer het opnieuw.',
      });
      return;
    }

    setLoading(true);
    scrollTo({ scrollToRef });
    setError();

    // The stage for loading the document is done,
    // now starting the stage to post the application(s)
    setStage('Formulier versturen');
    startProgressBar(25);

    const variables = {
      ...inputs,
      zipcode: formatZipcode(inputs.zipcode),
      applicantZipcode: formatZipcode(inputs.zipcode),
    };
    delete variables.isCompetent;
    delete variables.clientApproved;
    delete variables.termsAndConditions;

    // Start posting form
    await fetchApi({
      method: 'post',
      path: '/referral-form',
      variables,
    })
      .then(() => {
        handleSuccess();
      })
      .catch(err => {
        console.error(err);
        handleError(err);
      });
  };

  // Clean interval on unmount
  useEffect(() => () => clearInterval(progressBarInterval.current), []);

  return {
    inputs,
    handleChange,
    onSubmit,
    error,
    signError,
    loading,
    stage,
    progress,
  };
}
