/*
 * Join the elements in an javascript array,
 * but let the last separator be different eg: `and` / `or`
 * Stackoverflow link: http://stackoverflow.com/questions/15069587/is-there-a-way-to-join-the-elements-in-an-js-array-but-let-the-last-separator-b
 * Credit: Chris Barr - http://stackoverflow.com/users/79677/chris-barr
 */
function formatArrayIntoComma(arr) {
  if (arr.length === 1) {
    return arr[0];
  }
  if (arr.length === 2) {
    // joins all with "and" but no commas
    // example: "bob and sam"
    return arr.join(' en ');
  }
  if (arr.length > 2) {
    // joins all with commas, but last one gets ", and" (oxford comma!)
    // example: "bob, joe, and sam"
    return `${arr.slice(0, -1).join(', ')}, en ${arr.slice(-1)}`;
  }
  return '';
}

export default formatArrayIntoComma;
