import { JOBS_DOMAIN } from '@constants';

export function isCorporateDomain(
  host = process.env.CORPORATE_ENVIRONMENT_URL
) {
  return !host.includes(JOBS_DOMAIN);
}

export function isJobsDomain(host = process.env.CORPORATE_ENVIRONMENT_URL) {
  return host.includes(JOBS_DOMAIN);
}
