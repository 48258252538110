import PropTypes from 'prop-types';

import ctaType from './ctaType';

export default PropTypes.shape({
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      phone: PropTypes.string,
      isWhatsapp: PropTypes.bool,
    })
  ),
  email: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      email: PropTypes.string,
    })
  ),
  firstCtaText: PropTypes.string.isRequired,
  secondCta: ctaType,
  extraClasses: PropTypes.string,
  layout: PropTypes.string,
  modifier: PropTypes.string,
}).isRequired;
