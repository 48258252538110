import { locationPageFragment } from '@lib/fragments';
import { seoFields } from '@lib/fragments/seo';
import gql from 'graphql-tag';

const LOCATION_OVERVIEW_PAGE_QUERY = gql`
  query LOCATION_OVERVIEW_PAGE_QUERY($locale: String, $categories: [String]) {
    LocationOverviewPage(locale: $locale) {
      typename: __typename
      id: _id
      title
      ${seoFields}
    }
    LocationPages(
      limit: 50
      locale: $locale
      where: { categories: { _id_any: $categories } }
      sort: title_ASC
    ) {
      total
      items {
        ...locationFields
        position: location {
          lat: latitude
          lng: longitude
        }
      }
    }
    LocationCategories(limit: 50) {
      items {
        id: _id
        label
      }
	  }
  }
  ${locationPageFragment}
`;

export default LOCATION_OVERVIEW_PAGE_QUERY;
