import {
  BRUNSWIJCK_LOCATION_CODE,
  PARC_IMSTENRADE_LOCATION_CODE,
  PEPPELRODE_LOCATION_CODE,
  VITALIS_BESTUURSCENTRUM_LOCATION_CODE,
  VITALIS_DE_PALADIJN_LOCATION_CODE,
  VITALIS_DILLENBURGSTRAAT_LOCATION_CODE,
  VITALIS_FLEXPOOL_LOCATION_CODE,
  VITALIS_RESIDENTIE_DE_HOEVE_LOCATION_CODE,
  VITALIS_RESIDENTIE_GENDERSTATE_LOCATION_CODE,
  VITALIS_RESIDENTIE_GENNEP_LOCATION_CODE,
  VITALIS_RESIDENTIE_PETRUSPARK_LOCATION_CODE,
  VITALIS_RESIDENTIE_WILGENHOF_LOCATION_CODE,
  VITALIS_VONDERHOF_LOCATION_CODE,
  VITALIS_WISSEHAEGE_LOCATION_CODE,
  WONEN_AAN_DE_FAKKELLAAN_LOCATION_CODE,
  WOONINCPLUSVITALIS_BERCKELHOF_LOCATION_CODE,
  WOONINCPLUSVITALIS_DE_DREEF_LOCATION_CODE,
  WOONINCPLUSVITALIS_DE_HAGEN_LOCATION_CODE,
  WOONINCPLUSVITALIS_DE_HORST_LOCATION_CODE,
  WOONINCPLUSVITALIS_KORTONJO_LOCATION_CODE,
  WOONINCPLUSVITALIS_KRONEHOEF_LOCATION_CODE,
  WOONINCPLUSVITALIS_LINT_LOCATION_CODE,
  WOONINCPLUSVITALIS_MAXIMILIAAN_LOCATION_CODE,
  WOONINCPLUSVITALIS_PARC_GENDER_ENGELSBERGEN_LOCATION_CODE,
  WOONINCPLUSVITALIS_THERESIA_LOCATION_CODE,
} from '@constants';
import { useMemo } from 'react';

const codes = [
  WOONINCPLUSVITALIS_PARC_GENDER_ENGELSBERGEN_LOCATION_CODE,
  VITALIS_WISSEHAEGE_LOCATION_CODE,
  PEPPELRODE_LOCATION_CODE,
  VITALIS_RESIDENTIE_WILGENHOF_LOCATION_CODE,
  WOONINCPLUSVITALIS_BERCKELHOF_LOCATION_CODE,
  WOONINCPLUSVITALIS_DE_HORST_LOCATION_CODE,
  WOONINCPLUSVITALIS_KRONEHOEF_LOCATION_CODE,
  WOONINCPLUSVITALIS_LINT_LOCATION_CODE,
  WOONINCPLUSVITALIS_DE_DREEF_LOCATION_CODE,
  WOONINCPLUSVITALIS_DE_HAGEN_LOCATION_CODE,
  VITALIS_RESIDENTIE_DE_HOEVE_LOCATION_CODE,
  VITALIS_DE_PALADIJN_LOCATION_CODE,
  VITALIS_RESIDENTIE_GENDERSTATE_LOCATION_CODE,
  VITALIS_RESIDENTIE_GENNEP_LOCATION_CODE,
  WOONINCPLUSVITALIS_KORTONJO_LOCATION_CODE,
  WOONINCPLUSVITALIS_MAXIMILIAAN_LOCATION_CODE,
  WOONINCPLUSVITALIS_PARC_GENDER_ENGELSBERGEN_LOCATION_CODE,
  PARC_IMSTENRADE_LOCATION_CODE,
  VITALIS_RESIDENTIE_PETRUSPARK_LOCATION_CODE,
  WOONINCPLUSVITALIS_THERESIA_LOCATION_CODE,
  VITALIS_VONDERHOF_LOCATION_CODE,
  WONEN_AAN_DE_FAKKELLAAN_LOCATION_CODE,
  VITALIS_BESTUURSCENTRUM_LOCATION_CODE,
  VITALIS_DILLENBURGSTRAAT_LOCATION_CODE,
  VITALIS_FLEXPOOL_LOCATION_CODE,
  BRUNSWIJCK_LOCATION_CODE,
];

export default function useJobAlertLocations(locations) {
  return useMemo(
    () => locations.filter(location => codes.includes(location.code)),
    [locations]
  );
}
