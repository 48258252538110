import axios from 'axios';

export default function fetchApi({ method = 'get', path, variables = {} }) {
  const apiPath = `${process.env.SHARED_PREPR_API_ENDPOINT}${path}`;
  // TODO: remove the following line, this needs to be checked by DEV
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = 0;

  const params = Object.entries(variables)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => !!value)
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  if (method === 'post') {
    return axios.post(apiPath, {
      ...params,
    });
  }

  return axios.get(apiPath, {
    params: {
      ...params,
    },
  });
}
