import {
  corporateInternalLinkFields,
  jobsInternalLinkFields,
} from './internalLinks';

const sharedCtaFields = `
  id: _id
  title
  externalLink: external_link
  file {
    url
  }
`;

export const corporateCtaFields = `
  ${sharedCtaFields}
  internalLink: internal_link {
    ${corporateInternalLinkFields}
  }
`;

export const jobsCtaFields = `
  ${sharedCtaFields}
  internalLink: internal_link {
    ${jobsInternalLinkFields}
  }
`;
