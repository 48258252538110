'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import NProgress from 'nprogress';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

function NextNProgress({ startPosition = 0.3, stopDelayMs = 150 }) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  let timer;

  const routeChangeStart = () => {
    NProgress.set(startPosition);
    NProgress.start();
  };

  const routeChangeEnd = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      NProgress.done(true);
    }, stopDelayMs);
  };

  useEffect(() => {
    routeChangeStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    routeChangeEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return null;
}

NextNProgress.propTypes = {
  startPosition: PropTypes.number,
  stopDelayMs: PropTypes.number,
};

export default NextNProgress;
