import { jobsCtaFields } from '@lib/fragments';
import getJobAlertBlockFields from '@lib/fragments/jobAlertBlock';
import getRecruiterBlockFields from '@lib/fragments/recruiterBlock';
import {
  getSectionsFields,
  getSectionsFragments,
} from '@lib/fragments/sections';
import { seoFields } from '@lib/fragments/seo';
import gql from 'graphql-tag';

// TODO: Can we add a where on fields of work function id? Apollo was down when testing this
const MATCHMAKER_RESULTS_PAGE_QUERY = gql`
  query MATCHMAKER_RESULTS_PAGE_QUERY($locale: String) {
    MatchmakerResultsPage(locale: $locale) {
      typename: __typename
      id: _id
      title
      subtitle
      introText: intro_text
      image {
        ...assetFields
        url(width: 700, format: "webp")
        placeholder: url(width: 8)
      }
      jobAlertSection: job_alert_section {
        typename: __typename
        id: _id
        recruiterBlock: block_one {
          ${getRecruiterBlockFields({ ctaFields: jobsCtaFields })}
        }
        jobAlertBlock: block_two {
          ${getJobAlertBlockFields({ ctaFields: jobsCtaFields })}
        }
        backgroundColor: background_color {
          color
        }
      }
      ${getSectionsFields({ isCorporateDomain: false })}
      ${seoFields}
    }
    FieldsOfWork {
      items {
        id: _id
        title
        functionId: function_id
      }
    }
  }
  ${getSectionsFragments({ isCorporateDomain: false })}
`;

export default MATCHMAKER_RESULTS_PAGE_QUERY;
