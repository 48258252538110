import PropTypes from 'prop-types';

export default PropTypes.shape({
  title: PropTypes.string.isRequired,
  phone: PropTypes.string,
  email: PropTypes.string,
  url: PropTypes.shape({
    title: PropTypes.string,
    internalLink: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
      })
    ),
    externalLink: PropTypes.string,
  }),
  isWhatsapp: PropTypes.bool,
});
