import { assetFragment } from '@lib/fragments';
import {
  jobsSectionsFields,
  jobsSectionsFragment,
} from '@lib/fragments/sections';
import { seoFields } from '@lib/fragments/seo';
import gql from 'graphql-tag';

const JOBS_HOME_PAGE_QUERY = gql`
  query JOBS_HOME_PAGE_QUERY($locale: String) {
    HomePage(locale: $locale) {
      typename: __typename
      id: _id
      title
      subtitle
      image {
        url(width: 975, height: 645, format: "webp")
        placeholder: url(width: 8)
        ...assetFields
      }
      ${jobsSectionsFields}
      ${seoFields}
    }
  }
  ${assetFragment}
  ${jobsSectionsFragment}
`;

export default JOBS_HOME_PAGE_QUERY;
