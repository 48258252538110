import LoadingIcon from '@svg/progress-activity.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import styles from './Button.module.scss';
import WrapInButton from './WrapInButton';
import WrapInLink from './WrapInLink';

function ButtonInner({
  hideLabel,
  iconLeft,
  Icon,
  isRawLabel,
  label,
  loading,
}) {
  const labelClass = classNames(styles.label, hideLabel && 'u-visually-hidden');
  return (
    <span className={styles.inner}>
      {!!iconLeft && Icon && (
        <span className={styles.iconContainer} aria-hidden="true">
          <Icon className={styles.icon} />
        </span>
      )}

      {isRawLabel ? (
        <span
          className={labelClass}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: label }}
        />
      ) : (
        <span className={labelClass}>{label}</span>
      )}

      {!iconLeft && Icon && (
        <span className={styles.iconContainer} aria-hidden="true">
          <Icon className={styles.icon} />
        </span>
      )}

      {loading && <LoadingIcon className={styles.loadingIcon} />}
    </span>
  );
}

const Button = forwardRef(
  (
    {
      extraClasses,
      href,
      Icon,
      label,
      modifierClass,
      submit = false,
      loading = false,
      iconLeft = false,
      hideLabel = false,
      isRawLabel = false,
      noAnimation = false,
      size = 'regular',
      color = 'primary',
      variant = 'contained',
      ...attributes
    },
    ref
  ) => {
    const className = classNames(
      styles.button,
      styles[variant],
      styles[size],
      styles[color],
      extraClasses,
      hideLabel && styles.hideLabel,
      noAnimation && styles.noAnimation,
      loading && styles.loading,
      modifierClass && styles[modifierClass]
    );
    const title = hideLabel ? label : null;
    const childButtonAttrs = {
      hideLabel,
      iconLeft,
      Icon,
      isRawLabel,
      label,
      loading,
    };

    if (href) {
      return (
        <WrapInLink
          ref={ref}
          href={href}
          className={className}
          title={title}
          attributes={attributes}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ButtonInner {...childButtonAttrs} />
        </WrapInLink>
      );
    }
    return (
      <WrapInButton
        ref={ref}
        className={className}
        title={title}
        submit={submit}
        attributes={attributes}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <ButtonInner {...childButtonAttrs} />
      </WrapInButton>
    );
  }
);

ButtonInner.propTypes = {
  hideLabel: PropTypes.bool,
  iconLeft: PropTypes.bool,
  Icon: PropTypes.func,
  isRawLabel: PropTypes.bool,
  loading: PropTypes.bool,
  label: PropTypes.string,
};

Button.displayName = 'Button';

Button.propTypes = {
  label: PropTypes.string.isRequired,
  isRawLabel: PropTypes.bool,
  extraClasses: PropTypes.string,
  modifierClass: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  Icon: PropTypes.func,
  iconLeft: PropTypes.bool,
  submit: PropTypes.bool,
  hideLabel: PropTypes.bool,
  noAnimation: PropTypes.bool,
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  attributes: PropTypes.object,
  variant: PropTypes.oneOf(['text', 'contained', 'link']),
  size: PropTypes.oneOf(['small', 'regular', 'smallMobile']),
  color: PropTypes.oneOf(['primary', 'secondary', 'white']),
};

export default Button;
