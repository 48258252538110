import shiftTypes from '@config/matchmaker/shiftTypes';
import { useRouter } from 'next/router';

import useForm from '../useForm';

const skippableQuestions = {
  4: 'subjectAreas',
  6: 'shiftTypes',
};

/**
 * The filters parameter is only required when you want to use hasSelectedAll and toggleAll.
 */
export default function useMatchmakerQuestion({
  key,
  callback,
  initialState,
  saveStateAs,
  filters,
  optional = false,
}) {
  const { query } = useRouter();
  const { inputs, handleChange, setMultipleValues } = useForm({
    [key]: initialState
      ? initialState(query)
      : (typeof query[key] === 'string' ? [query[key]] : query[key]) || [],
  });

  const hasSelectedAll = filters && filters[key].length === inputs[key]?.length;

  const toggleAll = () => {
    setMultipleValues(
      hasSelectedAll
        ? { [key]: [] }
        : {
            [key]: filters[key].map(
              filter => filter.key || filter.id.toString()
            ),
          }
    );
  };

  const handlePreviousUrl = event => {
    const { slug, ...queryParameters } = query;

    callback(event, queryParameters);
  };

  const handleNextUrl = event => {
    event.preventDefault();

    if (!optional && !inputs[key]?.length) return;

    const { slug, ...queryParameters } = query;

    let parameters = queryParameters;

    // Delete parameters of a question when it is skipped
    const currentQuestion = +slug[0];
    const nextQuestion = +event.currentTarget.getAttribute('href').slice('-1');
    if (
      nextQuestion - currentQuestion > 1 &&
      skippableQuestions[nextQuestion - 1]
    ) {
      if (skippableQuestions[nextQuestion - 1] === 'subjectAreas') {
        delete parameters.subjectAreas;
      } else if (skippableQuestions[nextQuestion - 1] === 'shiftTypes') {
        shiftTypes.forEach(shiftType => {
          delete parameters[shiftType.key];
        });
      }
    }

    if (saveStateAs) {
      const state = saveStateAs(inputs[key]);
      parameters = { ...queryParameters, ...state };
    } else {
      parameters = { ...queryParameters, [key]: inputs[key] };
    }

    callback(event, parameters);
  };

  return {
    inputs,
    handleChange,
    hasSelectedAll,
    toggleAll,
    handlePreviousUrl,
    handleNextUrl,
  };
}
