import PropTypes from 'prop-types';

import imageType from './imageType';
import vacancyFiltersType from './vacancyFiltersType';

export default PropTypes.shape({
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  introText: PropTypes.string,
  cta: PropTypes.string.isRequired,
  image: imageType,
  filters: vacancyFiltersType,
}).isRequired;
