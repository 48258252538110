import { contentPageFragment } from '@lib/fragments';
import {
  getSectionsFields,
  getSectionsFragments,
} from '@lib/fragments/sections';
import gql from 'graphql-tag';

const getContentPageQuery = ({ isCorporateDomain }) => gql`
  query CONTENT_PAGE_QUERY($slug: String, $locale: String) {
    ContentPage(slug: $slug, locale: $locale) {
      ...contentPageFields
      ${getSectionsFields({ isCorporateDomain })}
    }
  }
  ${contentPageFragment}
  ${getSectionsFragments({ isCorporateDomain })}
`;

export default getContentPageQuery;
