import { locationPageFragment } from '@lib/fragments';
import gql from 'graphql-tag';

const LOCATION_PAGES_QUERY = gql`
  query LOCATION_PAGES_QUERY($locale: String, $categories: [String]) {
    LocationPages(
      limit: 50
      locale: $locale
      where: { categories: { _id_any: $categories } }
      sort: title_ASC
    ) {
      total
      items {
        ...locationFields
        position: location {
          lat: latitude
          lng: longitude
        }
      }
    }
  }
  ${locationPageFragment}
`;

export default LOCATION_PAGES_QUERY;
