import PropTypes from 'prop-types';

import imageType from './imageType';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  overviewText: PropTypes.string,
  image: imageType,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  eventInterval: PropTypes.string,
  intervalDay: PropTypes.string,
  intervalTiming: PropTypes.number,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string,
  location: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string.isRequired })
  ).isRequired,
});
