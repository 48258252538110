import PropTypes from 'prop-types';

import seoTypes from './seoTypes';
import vacancyItemType from './vacancyItemType';

export default PropTypes.shape({
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(vacancyItemType),
  total: PropTypes.number.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ),
  ...seoTypes,
});
