import Link from 'next/link';
import PropTypes from 'prop-types';

function NavigationItem({ item, extraClasses }) {
  const { internalLink, externalLink, label } = item;

  if (internalLink && internalLink[0]) {
    const slug = internalLink[0]?.slug;
    const pageType = internalLink[0]?.typename;

    let route;

    switch (pageType) {
      case 'NewsOverviewPage':
        route = '/actueel';
        break;
      case 'AgendaOverviewPage':
        route = '/agenda';
        break;
      case 'LocationOverviewPage':
        route = '/locaties';
        break;
      case 'StoryOverviewPage':
        route = '/verhalen';
        break;
      case 'VacancyOverviewPage':
        route = '/vacatures';
        break;
      case 'ContentPage':
        if (slug.indexOf('/') === 0) {
          route = slug;
        } else {
          route = `/${slug}`;
        }
        break;
      default:
        console.warn('Page type not recognized', { pageType, slug });
        return null;
    }

    return (
      <Link href={route} className={extraClasses}>
        {label}
      </Link>
    );
  }

  if (externalLink) {
    return (
      <a href={externalLink} target="_blank" className={extraClasses}>
        {label}
      </a>
    );
  }

  return null;
}

NavigationItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    internalLink: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        typename: PropTypes.string.isRequired,
      })
    ),
    externalLink: PropTypes.string,
  }),
  extraClasses: PropTypes.string,
};

export default NavigationItem;
