function getBase64(file) {
  return new Promise((resolve, reject) => {
    const fileName = file.name;
    const reader = new FileReader();

    if (!file) {
      reject();
    }

    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result.replace(/^.*,/, '');
      if (!base64String) {
        reject();
      }
      resolve({ base64String, fileName });
    };
    reader.onerror = error => {
      reject(error);
    };
  });
}

export function getFiles(files) {
  return Promise.all(files.map(getBase64));
}

export default getBase64;
