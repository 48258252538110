import { newsPageFragment } from '@lib/fragments';
import {
  getSectionsFields,
  getSectionsFragments,
} from '@lib/fragments/sections';
import { seoFields } from '@lib/fragments/seo';
import gql from 'graphql-tag';

const getNewsPageQuery = ({ isCorporateDomain }) => gql`
  query NEWS_PAGE_QUERY($slug: String, $locale: String) {
    NewsPage(slug: $slug, locale: $locale) {
      ...newsFields
      ${seoFields}
      ${getSectionsFields({ isCorporateDomain })}
    }
  }
  ${newsPageFragment}
  ${getSectionsFragments({ isCorporateDomain })}
`;

export default getNewsPageQuery;
