import { VACANCIES_PER_PAGE } from '@constants';
import fetchApi from '@lib/fetchApi';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';

import setUrlSearchParams from './setUrlSearchParams';
import useDidMountEffect from './useDidMountEffect';
import useForm from './useForm';
import usePagination from './usePagination';
import useSortedFilters from './useSortedFilters';

export default function useVacancies({
  data: initItems = [],
  total: initTotal = 0,
  filterOptions: initFilterOptions,
  error: initError,
  scrollToRef,
}) {
  const { query, ...router } = useRouter();
  // useMemo so we dont update the useForm inputs each time we update the query params
  const [inputsFromQuery] = useState({
    applicationForms: query.applicationForms
      ? query.applicationForms?.split(',')
      : [],
    functionCategories: query.functionCategories
      ? query.functionCategories?.split(',')
      : [],
    locations: query.locations ? query.locations?.split(',') : [],
    educationLevels: query.educationLevels
      ? query.educationLevels?.split(',')
      : [],
    subjectAreas: query.subjectAreas ? query.subjectAreas?.split(',') : [],
    minHours: query.minHours,
    maxHours: query.maxHours,
  });
  const { inputs, handleChange, clearForm } = useForm(inputsFromQuery);
  const [fetchTimeout, setFetchTimeout] = useState(useRef());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(initError);
  const hasActiveFilters = () =>
    !Object.values(inputs).some(
      v => (Array.isArray(v) && v.length) || (!Array.isArray(v) && v)
    );
  const [data, setData] = useState({
    items: initItems,
    total: initTotal,
    allResultsVisible: hasActiveFilters(),
    ...initFilterOptions,
  });

  const filters = [
    {
      title: 'Hoe wil je bij ons werken?',
      type: 'checkbox',
      name: 'applicationForms',
      options:
        useSortedFilters({
          applicationForms: data?.applicationForms,
        }).applicationForms?.map(func => ({
          ...func,
          label: func.title,
          checked: inputs.applicationForms.includes(`${func.id}`),
        })) || [],
      onChange: handleChange,
    },
    {
      title: 'Werkveld',
      type: 'checkbox',
      name: 'functionCategories',
      options:
        useSortedFilters({
          functionCategories: data?.functionCategories,
        }).functionCategories?.map(func => ({
          ...func,
          label: func.title,
          checked: inputs.functionCategories.includes(`${func.id}`),
        })) || [],
      onChange: handleChange,
    },
    {
      title: 'Doelgroep',
      type: 'checkbox',
      name: 'subjectAreas',
      options:
        useSortedFilters({
          subjectAreas: data?.subjectAreas,
        }).subjectAreas?.map(edu => ({
          ...edu,
          label: edu.title,
          checked: inputs.subjectAreas.includes(`${edu.id}`),
        })) || [],
      onChange: handleChange,
    },
    {
      title: 'Opleidingsniveau',
      type: 'checkbox',
      name: 'educationLevels',
      options:
        data?.educationLevels?.map(edu => ({
          ...edu,
          label: edu.title,
          checked: inputs.educationLevels.includes(`${edu.id}`),
        })) || [],
      onChange: handleChange,
    },
    {
      title: 'Locaties',
      type: 'checkbox',
      name: 'locations',
      options:
        data?.locations?.map(loc => ({
          ...loc,
          label: loc.title,
          checked: inputs.locations.includes(`${loc.id}`),
        })) || [],
      onChange: handleChange,
    },
    {
      title: 'Aantal uren',
      type: 'numberRange',
      names: ['minHours', 'maxHours'],
      values: [inputs.minHours, inputs.maxHours],
      onChange: handleChange,
    },
  ];

  const handleSuccess = ({ data: items, total, filterOptions }) => {
    setLoading(false);
    setData({
      items,
      total,
      allResultsVisible: hasActiveFilters(),
      ...filterOptions,
    });
  };

  const handleError = error => {
    console.error(error);
    setLoading(false);
    setError(error);
  };

  const fetchItems = ({ page }) => {
    setLoading(true);

    Promise.all([
      fetchApi({
        method: 'post',
        path: '/vacancies',
        variables: {
          page,
          limit: VACANCIES_PER_PAGE,
          ...inputs,
        },
      }),
      fetchApi({
        method: 'post',
        path: '/vacancies/active-filter-options',
        variables: {
          ...inputs,
        },
      }),
    ])
      .then(res => {
        handleSuccess({ ...res[0].data, filterOptions: res[1].data });
      })
      .catch(e => handleError(e));
  };

  const {
    currentPage,
    totalPages,
    hasNextPage,
    hasPrevPage,
    handleNextPage,
    handlePrevPage,
    handleGoToPage,
    resetPagination,
  } = usePagination({
    total: data.total,
    fetchMore: fetchItems,
    limit: VACANCIES_PER_PAGE,
    scrollToRef,
  });

  useDidMountEffect(() => {
    setFetchTimeout(clearTimeout(fetchTimeout));

    setFetchTimeout(
      setTimeout(() => {
        resetPagination();
        setUrlSearchParams({
          params: { ...query, ...inputs, page: 1 },
          router,
        });
        fetchItems({ page: 1 });
      }, 200)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  return {
    ...data,
    filters,
    clearFilters: clearForm,
    inputs,
    loading,
    error,
    currentPage,
    totalPages,
    hasNextPage,
    hasPrevPage,
    handleNextPage,
    handlePrevPage,
    handleGoToPage,
    resetPagination,
  };
}
