import PropTypes from 'prop-types';

export default PropTypes.shape({
  minHours: PropTypes.number,
  maxHours: PropTypes.number,
  minSalary: PropTypes.string,
  maxSalary: PropTypes.string,
  education: PropTypes.string,
  location: PropTypes.string,
});
