import { assetFragment } from '@lib/fragments';
import { seoFields } from '@lib/fragments/seo';
import gql from 'graphql-tag';

const MATCHMAKER_PAGE_QUERY = gql`
  query MATCHMAKER_PAGE_QUERY($locale: String) {
    MatchmakerPage(locale: $locale) {
      typename: __typename
      id: _id
      title
      subtitle
      introText: intro_text
      cta
      image {
        ...assetFields
        url(width: 700, format: "webp")
        placeholder: url(width: 8)
      }
      imageQuestion1: image_question_1 {
        ...assetFields
        url(width: 700, format: "webp")
        placeholder: url(width: 8)
      }
      imageQuestion2: image_question_2 {
        ...assetFields
        url(width: 700, format: "webp")
        placeholder: url(width: 8)
      }
      imageQuestion3: image_question_3 {
        ...assetFields
        url(width: 700, format: "webp")
        placeholder: url(width: 8)
      }
      imageQuestion4: image_question_4 {
        ...assetFields
        url(width: 700, format: "webp")
        placeholder: url(width: 8)
      }
      imageQuestion5: image_question_5 {
        ...assetFields
        url(width: 700, format: "webp")
        placeholder: url(width: 8)
      }
      imageQuestion6: image_question_6 {
        ...assetFields
        url(width: 700, format: "webp")
        placeholder: url(width: 8)
      }
      imageQuestion7: image_question_7 {
        ...assetFields
        url(width: 700, format: "webp")
        placeholder: url(width: 8)
      }
      ${seoFields}
    }
  }
  ${assetFragment}
`;

export default MATCHMAKER_PAGE_QUERY;
