import Button from '@atoms/Button/Button';
import ContactLink from '@atoms/ContactLink/ContactLink';
import AddIcon from '@svg/add.svg';
import ChevronDownIcon from '@svg/chevron-down.svg';
import CrossIcon from '@svg/cross.svg';
import HelpIcon from '@svg/help.svg';
import RemoveIcon from '@svg/remove.svg';
import { getCookie, setCookie } from '@utils/clientCookies';
import { useEffect, useState } from 'react';

import styles from './AccessibilityPanel.module.scss';

const panelId = 'accessibility-panel-id';
const options = [1, 1.1, 1.2];

const FONT_SIZE_COOKIE = 'a11yFontSize';
const HIDE_PANEL_COOKIE = 'hideA11y';

export default function AccessibilityPanel() {
  const [isHidden, setIsHidden] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [fontSize, setFontSize] = useState(0);

  /* Expand and collapse a11y panel */
  const togglePanel = () => {
    setIsExpanded(exp => !exp);
  };

  /* Set cookie that hides panel for user */
  const hidePanel = () => {
    setCookie(HIDE_PANEL_COOKIE, true);
    setIsHidden(true);
  };

  /* In- or decrease font size on :root element */
  const updateFontSize = (direction = 'increase') => {
    setFontSize(i => {
      let newValue = i;

      if (direction === 'increase') {
        newValue += 1;
      }

      if (direction === 'decrease') {
        newValue -= 1;
      }

      setCookie(FONT_SIZE_COOKIE, newValue);

      return newValue;
    });
  };

  /* Check if fontsize is set in cookies */
  useEffect(() => {
    const fs = getCookie(FONT_SIZE_COOKIE);
    const hide = getCookie(HIDE_PANEL_COOKIE);

    if (fs) {
      setFontSize(Number(fs));
    }

    if (!hide) {
      setIsHidden(false);
    }
  }, []);

  /* Update font-size property on the :root element */
  useEffect(() => {
    document.querySelector(':root').style.fontSize = `${
      options[fontSize] * 16
    }px`;
  }, [fontSize]);

  /* Hide panel if user disabled the panel */
  if (isHidden) return null;

  return (
    <div className={styles.panel}>
      <button
        type="button"
        onClick={togglePanel}
        className={styles.button}
        aria-controls={panelId}
        aria-expanded={isExpanded}
      >
        <HelpIcon className={styles.icon} />
        <span className={styles.label}>Hulp bij gebruik van deze website</span>
        <ChevronDownIcon className={styles.icon} />
      </button>

      <div className={styles.content} id={panelId}>
        {/* Update fontsize */}
        <div className={styles.fontSize}>
          <p className="u-mb-0">
            <b>Tekstgrootte:</b>
            <br />
            {Math.round(100 * options[fontSize])}%
          </p>
          <div className={styles.buttonsContainer}>
            <button
              type="button"
              onClick={() => updateFontSize('decrease')}
              aria-label="Verklein tekstgrootte"
              title="Verklein tekstgrootte"
              disabled={fontSize === 0}
              className={styles.sizeButton}
            >
              <RemoveIcon className={styles.sizeIcon} />
            </button>
            <button
              type="button"
              onClick={() => updateFontSize('increase')}
              aria-label="Vergroot tekstgrootte"
              title="Vergroot tekstgrootte"
              disabled={fontSize === options.length - 1}
              className={styles.sizeButton}
            >
              <AddIcon className={styles.sizeIcon} />
            </button>
          </div>
        </div>

        {/* Digital help */}
        <p className="u-mb-0">
          <b>Digitale hulp nodig?</b>
          <br />
          Lukt het niet online? Getrainde vrijwilligers helpen graag en kunnen
          alle vragen beantwoorden over de computer, laptop, mobiel én tablet.
          Bel de DigiHulplijn gratis van maandag t/m vrijdag tussen 08.00 -
          22.00 uur en zaterdag tussen 09.00 - 18.00 uur
        </p>

        <ContactLink link={{ title: '0800 - 1508', phone: '08001508' }} />

        <p className="u-mb-0">
          De knop &apos;Hulp bij gebruik van deze website&apos; niet meer zien
          op de website?
        </p>

        <Button
          variant="link"
          label="Niet meer tonen"
          onClick={hidePanel}
          iconLeft
          noAnimation
          Icon={CrossIcon}
        />
      </div>
    </div>
  );
}
