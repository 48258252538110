import gql from 'graphql-tag';

const CONTENT_SLUGS_QUERY = gql`
  query CONTENT_SLUGS_QUERY {
    ContentPages(sort: publish_on_DESC) {
      typename: __typename
      items {
        slug: _slug
      }
    }
  }
`;

export default CONTENT_SLUGS_QUERY;
