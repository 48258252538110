import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const WrapInButton = forwardRef(
  ({ className, title, submit, attributes, children }, ref) => (
    <button
      ref={ref}
      className={className}
      type={submit ? 'submit' : 'button'}
      title={title}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
    >
      {children}
    </button>
  )
);

WrapInButton.displayName = 'WrapInButton';

WrapInButton.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  submit: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  attributes: PropTypes.object,
  children: PropTypes.element,
};

export default WrapInButton;
