import { assetFragment } from '@lib/fragments';
import {
  jobsSectionsFields,
  jobsSectionsFragment,
} from '@lib/fragments/sections';
import gql from 'graphql-tag';

const VACANCY_THANKS_PAGE_QUERY = gql`
  query VACANCY_THANKS_PAGE_QUERY($locale: String) {
    VacancyThanksPage(locale: $locale) {
      typename: __typename
      id: _id
      title
      metaTitle: meta_title
      metaDescription: meta_description
      ogImage: og_image {
        ...assetFields
        url(width: 1280, format: "webp")
      }
      ${jobsSectionsFields}
    }
  }
  ${assetFragment}
  ${jobsSectionsFragment}
`;

export default VACANCY_THANKS_PAGE_QUERY;
