import { refType } from '@lib/proptypes';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './CloseButton.module.scss';

export default function CloseButton({
  onClick,
  label,
  controls,
  controlIsOpen,
  extraClasses,
  buttonRef,
}) {
  return (
    <button
      type="button"
      className={classNames(styles.button, extraClasses)}
      onClick={onClick}
      aria-label={label}
      aria-expanded={controlIsOpen}
      aria-haspopup
      aria-controls={controls}
      ref={buttonRef}
    >
      <div className={styles.icon}>
        <span />
        <span />
      </div>
    </button>
  );
}

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  controls: PropTypes.string.isRequired,
  controlIsOpen: PropTypes.bool.isRequired,
  extraClasses: PropTypes.string,
  buttonRef: refType,
};
