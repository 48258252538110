import { assetFragment } from '@lib/fragments';
import gql from 'graphql-tag';

const getImageSectionFragment = ({ ctaFields }) => gql`
  fragment imageSectionFields on ImageSection {
    typename: __typename
    id: _id
    title
    text
    cta {
      ${ctaFields}
    }
    image {
      ...assetFields
      url(width: 3042, format: "webp")
      placeholder: url(width: 8)
    }
    imageAlt: alt_text
    backgroundColor: background_color {
      color
    }
  }
  ${assetFragment}
`;

export default getImageSectionFragment;
