export const BG_WHITE = 'white';
export const BG_CREME = 'creme';
export const BG_RED = 'red';
export const BG_GREEN = 'green';
export const BG_COLORS = [BG_WHITE, BG_CREME, BG_RED, BG_GREEN];
// Map our locales to the Prepr i18n locales
export const LOCALES = {
  nl: 'nl-NL',
  en: 'en-GB',
};
export const ITEMS_PER_PAGE = 6;
export const SCROLL_OFFSET = 64;
export const VACANCIES_PER_PAGE = 10;
export const SEARCH_SUGGESTIONS_PER_PAGE = 5;
export const SEARCH_RESULTS_PER_PAGE = 10;
export const CORPORATE_DOMAIN = 'vitalis';
export const JOBS_DOMAIN = 'werkenbijvitalis';
export const MAXIMUM_RELATED_ITEMS = 3;
export const MINIMUM_QUERY_LENGTH = 3;
export const ITEMS_ABOVE_FOLD = 5;

export const STUDENT_APPLICATION_CODE = '104';
export const HOLIDAY_WORKER_APPLICATION_CODE = '457';
export const INTERN_APPLICATION_CODE = '497';
export const FLEX_WORKER_APPLICATION_CODE = '103';
export const VOLUNTEER_APPLICATION_CODE = '153';
export const ZZP_APPLICATION_CODE = '257';
export const COLLEAGUE_APPLICATION_CODE = '474';

export const STUDENT_APPLICATION_ID = '1';
export const HOLIDAY_WORKER_APPLICATION_ID = '2';
export const INTERN_APPLICATION_ID = '3';
export const FLEX_WORKER_APPLICATION_ID = '4';
export const VOLUNTEER_APPLICATION_ID = '5';
export const ZZP_APPLICATION_ID = '6';
export const COLLEAGUE_APPLICATION_ID = '7';

export const WELL_BEING_FUNCTION_CATEGORY_CODE = 'WEL';
export const HEALTHCARE_FUNCTION_CATEGORY_CODE = 'ZG';
export const CATERING_INDUSTRY_FUNCTION_CATEGORY_CODE = 'HOR';
export const FACILITY_FUNCTION_CATEGORY_CODE = 'FAC';
export const TREATMENT_FUNCTION_CATEGORY_CODE = 'BHG';
export const STAFF_AND_ADMINISTRATION_FUNCTION_CATEGORY_CODE = 'ADM';
export const MANAGEMENT_AND_BOARD_FUNCTION_CATEGORY_CODE = 'DIR';

export const HOME_CARE_SUBJECT_AREA_CODE = '01';
export const PSYCHIATRY_SUBJECT_AREA_CODE = '02';
export const PSYCHOGERIATRICS_SUBJECT_AREA_CODE = '03';
export const REVALIDATION_SUBJECT_AREA_CODE = '04';
export const SOMATICS_SUBJECT_AREA_CODE = '05';
export const DAYTIME_ACTIVITIES_SUBJECT_AREA_CODE = '10';

export const MORNING_SHIFT_KEY = 'shiftTypeMorning';
export const AFTERNOON_SHIFT_KEY = 'shiftTypeNoon';
export const EVENING_SHIFT_KEY = 'shiftTypeEvening';
export const NIGHT_SHIFT_KEY = 'shiftTypeNight';
export const WEEKEND_SHIFT_KEY = 'shiftTypeWeekend';
export const SHORT_SHIFT_KEY = 'shiftTypeShort';
export const LONG_SHIFT_KEY = 'shiftTypeLong';

export const BRUNSWIJCK_LOCATION_CODE = '3054002';
export const PEPPELRODE_LOCATION_CODE = '3054007';
export const WONEN_AAN_DE_FAKKELLAAN_LOCATION_CODE = '3054018';
export const WOONINCPLUSVITALIS_DE_HORST_LOCATION_CODE = '3054004';
export const WOONINCPLUSVITALIS_KRONEHOEF_LOCATION_CODE = '3054006';
export const WOONINCPLUSVITALIS_DE_DREEF_LOCATION_CODE = '3054023';
export const VITALIS_RESIDENTIE_PETRUSPARK_LOCATION_CODE = '3054008';
export const VITALIS_RESIDENTIE_DE_HOEVE_LOCATION_CODE = '3054024';

export const VITALIS_VONDERHOF_LOCATION_CODE = '3054010';
export const WOONINCPLUSVITALIS_PARC_GENDER_ENGELSBERGEN_LOCATION_CODE =
  '3054003';
export const WOONINCPLUSVITALIS_MAXIMILIAAN_LOCATION_CODE = '3054025';
export const WOONINCPLUSVITALIS_DE_HAGEN_LOCATION_CODE = '3054026';
export const WOONINCPLUSVITALIS_BERCKELHOF_LOCATION_CODE = '3054001';
export const WOONINCPLUSVITALIS_THERESIA_LOCATION_CODE = '3054009';
export const VITALIS_RESIDENTIE_GENDERSTATE_LOCATION_CODE = '3054027';

export const VITALIS_WISSEHAEGE_LOCATION_CODE = '3054014';
export const WOONINCPLUSVITALIS_LINT_LOCATION_CODE = '3054021';
export const WOONINCPLUSVITALIS_KORTONJO_LOCATION_CODE = '3054005';
export const WOONINCPLUSVITALIS_WILGENHOF_LOCATION_CODE = '3054011';
export const VITALIS_RESIDENTIE_WILGENHOF_LOCATION_CODE = '3054029';
export const VITALIS_RESIDENTIE_GENNEP_LOCATION_CODE = '3054028';

export const VITALIS_DE_PALADIJN_LOCATION_CODE = '3054017';

export const PARC_IMSTENRADE_LOCATION_CODE = '3054015';

export const VITALIS_FLEXPOOL_LOCATION_CODE = '3054013';

export const VITALIS_BESTUURSCENTRUM_LOCATION_CODE = '3054019';

export const VITALIS_DILLENBURGSTRAAT_LOCATION_CODE = '3054020';

export const VITALIS_FLEXPOOL_CLUSTER_ID = 6;
export const VITALIS_BESTUURSCENTRUM_CLUSTER_ID = 7;
export const VITALIS_DILLENBURGSTRAAT_CLUSTER_ID = 8;
