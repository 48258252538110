import { contactLinkType } from '@lib/proptypes';
import InternetGlobe from '@svg/internet-globe.svg';
import MailIcon from '@svg/mail.svg';
import PhoneIcon from '@svg/phone.svg';
import WhatsappIcon from '@svg/whatsapp.svg';
import getInternalUrl from '@utils/getInternalUrl';

import styles from './ContactLink.module.scss';

function ContactLink({ link }) {
  const { email, phone, isWhatsapp, url, title } = link;

  if (email) {
    return (
      <a href={`mailto:${email}`} title={email} className={styles.link}>
        <MailIcon className={styles.icon} />
        {title}
      </a>
    );
  }

  if (phone && isWhatsapp) {
    const href = `https://wa.me/${phone
      .replaceAll('-', '')
      .replaceAll(' ', '')}`;

    return (
      <a href={href} title={phone} className={styles.link} target="_blank">
        <WhatsappIcon className={styles.icon} />
        {title}
      </a>
    );
  }

  if (phone && !isWhatsapp) {
    return (
      <a href={`tel:${phone}`} title={phone} className={styles.link}>
        <PhoneIcon className={styles.icon} />
        {title}
      </a>
    );
  }

  if (url?.externalLink) {
    return (
      <a
        href={url.externalLink}
        title={title}
        target="_blank"
        className={styles.link}
      >
        <InternetGlobe className={styles.icon} />
        {title}
      </a>
    );
  }

  if (url?.internalLink) {
    return (
      <a
        href={getInternalUrl(url.internalLink[0].slug)}
        title={title}
        target="_self"
        className={styles.link}
      >
        <InternetGlobe className={styles.icon} />
        {title}
      </a>
    );
  }

  return null;
}

ContactLink.propTypes = {
  link: contactLinkType,
};

export default ContactLink;
