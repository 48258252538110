import gql from 'graphql-tag';

const getContactSectionFragment = ({ ctaFields }) => gql`
  fragment contactSectionFields on ContactSection {
    typename: __typename
    id: _id
    title
    text
    phone {
      id: _id
      title
      phone
      isWhatsapp: is_whatsapp
    }
    email {
      id: _id
      title
      email
    }
    cta {
      ${ctaFields}
    }
    backgroundColor: background_color {
      color
    }
  }
`;

export default getContactSectionFragment;
