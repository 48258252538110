import { seoFields } from '@lib/fragments/seo';
import gql from 'graphql-tag';

const REFERRAL_PAGE_QUERY = gql`
  query REFERRAL_PAGE_QUERY($locale: String) {
    ReferralPage(locale: $locale) {
      typename: __typename
      id: _id
      title
      ${seoFields}
    }
  }
`;

export default REFERRAL_PAGE_QUERY;
