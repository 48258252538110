import gql from 'graphql-tag';

const NEWS_SLUGS_QUERY = gql`
  query NEWS_SLUGS_QUERY {
    NewsPages(sort: publish_on_DESC) {
      typename: __typename
      items {
        slug: _slug
      }
    }
  }
`;

export default NEWS_SLUGS_QUERY;
