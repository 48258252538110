const FOCUSABLE_ELS_SELECTOR =
  'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])';

export default function useFocusTrap(ref, toggleRef) {
  const shouldTrapFocus = e => {
    const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
    if (!isTabPressed) return false;
    return true;
  };

  const trapFocus = e => {
    if (!shouldTrapFocus(e)) return;

    const focusableEls = ref.current.querySelectorAll(FOCUSABLE_ELS_SELECTOR);

    const lastElementIsFocused =
      document.activeElement === focusableEls[focusableEls.length - 1];

    // Trap focus in the current list of elements
    if (e.shiftKey) {
      if (document.activeElement === toggleRef.current) {
        focusableEls[focusableEls.length - 1].focus();
        e.preventDefault();
      }
    } else if (lastElementIsFocused) {
      focusableEls[0].focus();
      e.preventDefault();
    }
  };

  const enableFocusTrap = () => {
    ref.current.addEventListener('keydown', trapFocus);
  };

  const disableFocusTrap = () => {
    ref.current.removeEventListener('keydown', trapFocus);
  };

  return { trapFocus, enableFocusTrap, disableFocusTrap };
}
