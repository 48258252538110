import PropTypes from 'prop-types';

import imageType from './imageType';

export default PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  fieldOfWork: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    headerImages: PropTypes.arrayOf(imageType),
    atmosphericImages: PropTypes.arrayOf(imageType),
    whatToExpectItems: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        icon: PropTypes.shape({
          icon: PropTypes.string,
        }),
      })
    ),
  }),
});
