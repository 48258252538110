import newsFragment from '@lib/fragments/newsPage';
import { seoFields } from '@lib/fragments/seo';
import gql from 'graphql-tag';

const NEWS_OVERVIEW_PAGE_QUERY = gql`
  query NEWS_OVERVIEW_PAGE_QUERY(
    $limit: Int
    $skip: Int
    $locale: String
    $categories: [String]
  ) {
    NewsOverviewPage(locale: $locale) {
      typename: __typename
      id: _id
      title
      intro: intro_text
      ${seoFields}
    }
    NewsPages(
      limit: $limit
      skip: $skip
      locale: $locale,
      where: { categories: { _id_any: $categories } },
      sort: publish_on_DESC,
    ) {      
      items {
        ...newsFields
      }
      total
    }
    NewsCategories(limit: 50) {
      items {
        id: _id
        label
      }
    }
  }
  ${newsFragment}
`;

export default NEWS_OVERVIEW_PAGE_QUERY;
