import {
  corporateSectionsFields,
  corporateSectionsFragment,
} from '@lib/fragments/sections';
import { seoFields } from '@lib/fragments/seo';
import gql from 'graphql-tag';

const REFERRAL_THANKS_PAGE_QUERY = gql`
  query REFERRAL_THANKS_PAGE_QUERY {
    ReferralThanksPage {
      typename: __typename
      id: _id
      title
      ${seoFields}
      ${corporateSectionsFields}
    }
  }
  ${corporateSectionsFragment}
`;

export default REFERRAL_THANKS_PAGE_QUERY;
