import PropTypes from 'prop-types';

import imageType from './imageType';
import seoTypes from './seoTypes';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  text: PropTypes.string,
  image: imageType,
  imageAlt: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  publishOn: PropTypes.string,
  ...seoTypes,
});
