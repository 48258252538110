import gql from 'graphql-tag';

import assetFragment from './asset';
import locationFragment from './locationPage';

const eventPageFragment = gql`
  fragment eventFields on EventPage {
    typename: __typename
    id: _id
    title
    slug: _slug
    text
    image {
      ...assetFields
      placeholder: url(width: 8)
      url(width: 400, height: 300, format: "webp")
    }
    imageAlt: alt_text
    categories {
      id: _id
      label
    }
    highlightedMessage: highlighted_message
    highlightedEvent: highlighted_event
    eventInterval: event_interval
    intervalDay: interval_day
    intervalTiming: interval_timing
    startDate: start_date
    endDate: end_date
    startTime: start_time
    endTime: end_time
    location {
      ...locationFields
    }
    contactName: contact_name
    contactEmail: contact_email
    contactPhone: contact_phone
  }
  ${assetFragment}
  ${locationFragment}
`;

export default eventPageFragment;
