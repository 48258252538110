import { assetFragment, newsPageFragment } from '@lib/fragments';
import gql from 'graphql-tag';

const getRelatedNewsSectionFragment = ({ ctaFields }) => gql`
  fragment relatedNewsSectionFields on RelatedNewsSection {
    typename: __typename
    id: _id
    title
    text
    selection: related_news {
      ...newsFields
    }
    categories {
      id: _id
      label
    }
    cta {
      ${ctaFields}
    }
  }
  ${assetFragment}
  ${newsPageFragment}
`;

export default getRelatedNewsSectionFragment;
