import { assetFragment } from '@lib/fragments';
import {
  jobsSectionsFields,
  jobsSectionsFragment,
} from '@lib/fragments/sections';
import gql from 'graphql-tag';

const FIELD_OF_WORK_QUERY = gql`
  query FIELD_OF_WORK_QUERY($recruiterCode: String!, $functionCategoryCode: String!, $applicationFormCode: String!) {
    FieldsOfWork(where: { function_id: $functionCategoryCode }) {
      items {
        id: _id
        title
        headerImages: header_images {
          ...assetFields
          url(width: 3042, format: "webp")
          placeholder: url(width: 8)
        }
        atmosphericImages: atmospheric_images { 
          ...assetFields
          url(width: 3042, format: "webp")
          placeholder: url(width: 8)
        }
        whatToExpectItems: what_to_expect_items { 
          title
          description
          icon {
            icon
          }
        }
        storiesTitle: stories_title
        storiesText: stories_text
        ${jobsSectionsFields}
      }
    }
    ApplicationProcesses(where: { application_form_code: $applicationFormCode }) {
      items {
        title
        text
        applicationProcessItems: application_process_items {
          title
          text
          image {
            ...assetFields
            url(width: 3042, format: "webp")
            placeholder: url(width: 8)
          }
        }
      }
    }
    StoryPages(
      limit: 3
      where: { fields_of_work: { function_id: $functionCategoryCode} }) {
      stories: items {
        id: _id
        slug: _slug
        title
        text
        image {
          ...assetFields
          url(width: 3080, format: "webp")
          placeholder: url(width: 8)
        }
        imageAlt: alt_text
        fieldsOfWork: fields_of_work {
          id: _id
          label: title
        }
      }
    }
    Recruiters(where: {recruiter_code: $recruiterCode }) {
      items {
        typename: __typename
        id: _id
        name
        image {
          ...assetFields
          url(width: 210, height: 210, format: "webp")
          placeholder: url(width: 8, height: 8)
        }
        phone {
          id: _id
          title
          phone
          isWhatsapp: is_whatsapp
        }
        email {
          id: _id
          title
          email
        }
      }
    }
  }
  ${assetFragment}
  ${jobsSectionsFragment}
`;

export default FIELD_OF_WORK_QUERY;
