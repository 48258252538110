import { getLocationPages } from '@lib/prepr';
import { useRouter } from 'next/router';
import { useMemo, useRef, useState } from 'react';

import scrollTo from './scrollTo';
import setUrlSearchParams from './setUrlSearchParams';
import useDidMountEffect from './useDidMountEffect';
import useForm from './useForm';
import useStore from './useStore';

export default function useLocations({
  items: initItems,
  total: initTotal,
  categories,
  scrollToRef,
}) {
  const {
    store: { host, preview },
  } = useStore();
  const { query, ...router } = useRouter();
  // useMemo so we dont update the useForm inputs each time we update the query params
  const inputsFromQuery = useMemo(
    () => ({
      categories: query.categories ? query.categories?.split(',') : [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const { inputs, handleChange, clearForm } = useForm(inputsFromQuery);
  const [fetchTimeout, setFetchTimeout] = useState(useRef());
  const [loading, setLoading] = useState(false);
  const hasActiveFilters = () =>
    !Object.values(inputs).some(
      v => (Array.isArray(v) && v.length) || (!Array.isArray(v) && v)
    );
  const [data, setData] = useState({
    items: initItems,
    total: initTotal,
    allResultsVisible: hasActiveFilters(),
  });

  const filters = [
    {
      title: 'Categorie',
      type: 'checkbox',
      name: 'categories',
      options: categories.map(cat => ({
        ...cat,
        checked: inputs.categories.includes(cat.id),
      })),
      onChange: handleChange,
    },
  ];

  const fetchItems = async () => {
    setLoading(true);
    const { items, total } = await getLocationPages({
      ...inputs,
      host,
      preview,
    });
    setLoading(false);
    setData({ items, total, allResultsVisible: hasActiveFilters() });
    scrollTo({ scrollToRef });
  };

  useDidMountEffect(() => {
    setFetchTimeout(clearTimeout(fetchTimeout));

    setFetchTimeout(
      setTimeout(() => {
        setUrlSearchParams({ params: { ...query, ...inputs }, router });
        fetchItems();
      }, 200)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  return { ...data, filters, clearFilters: clearForm, loading };
}
