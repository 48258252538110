import PropTypes from 'prop-types';

const menuLinkProps = {
  id: PropTypes.string,
  label: PropTypes.string,
  internalLink: PropTypes.arrayOf(
    PropTypes.shape({
      typename: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  externalLink: PropTypes.string,
};

export default PropTypes.shape({
  ...menuLinkProps,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      ...menuLinkProps,
    })
  ),
});
