import {
  CATERING_INDUSTRY_FUNCTION_CATEGORY_CODE,
  COLLEAGUE_APPLICATION_CODE,
  DAYTIME_ACTIVITIES_SUBJECT_AREA_CODE,
  FACILITY_FUNCTION_CATEGORY_CODE,
  HEALTHCARE_FUNCTION_CATEGORY_CODE,
  HOLIDAY_WORKER_APPLICATION_CODE,
  HOME_CARE_SUBJECT_AREA_CODE,
  INTERN_APPLICATION_CODE,
  MANAGEMENT_AND_BOARD_FUNCTION_CATEGORY_CODE,
  PSYCHIATRY_SUBJECT_AREA_CODE,
  PSYCHOGERIATRICS_SUBJECT_AREA_CODE,
  REVALIDATION_SUBJECT_AREA_CODE,
  SOMATICS_SUBJECT_AREA_CODE,
  STAFF_AND_ADMINISTRATION_FUNCTION_CATEGORY_CODE,
  STUDENT_APPLICATION_CODE,
  TREATMENT_FUNCTION_CATEGORY_CODE,
  VOLUNTEER_APPLICATION_CODE,
  WELL_BEING_FUNCTION_CATEGORY_CODE,
  ZZP_APPLICATION_CODE,
} from '@constants';

const sort = {
  applicationForms: [
    COLLEAGUE_APPLICATION_CODE,
    STUDENT_APPLICATION_CODE,
    VOLUNTEER_APPLICATION_CODE,
    INTERN_APPLICATION_CODE,
    HOLIDAY_WORKER_APPLICATION_CODE,
    ZZP_APPLICATION_CODE,
  ],
  functionCategories: [
    WELL_BEING_FUNCTION_CATEGORY_CODE,
    HEALTHCARE_FUNCTION_CATEGORY_CODE,
    TREATMENT_FUNCTION_CATEGORY_CODE,
    FACILITY_FUNCTION_CATEGORY_CODE,
    CATERING_INDUSTRY_FUNCTION_CATEGORY_CODE,
    STAFF_AND_ADMINISTRATION_FUNCTION_CATEGORY_CODE,
    MANAGEMENT_AND_BOARD_FUNCTION_CATEGORY_CODE,
  ],
  subjectAreas: [
    HOME_CARE_SUBJECT_AREA_CODE,
    PSYCHOGERIATRICS_SUBJECT_AREA_CODE,
    SOMATICS_SUBJECT_AREA_CODE,
    REVALIDATION_SUBJECT_AREA_CODE,
    PSYCHIATRY_SUBJECT_AREA_CODE,
    DAYTIME_ACTIVITIES_SUBJECT_AREA_CODE,
  ],
};

export default sort;
