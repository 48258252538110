const getRecruiterBlockFields = ({ ctaFields }) => `
  typename: __typename
  id: _id
  title
  text
  recruiter {
    typename: __typename
    id: _id
    name
    image {
      ...assetFields
      url(width: 210, height: 210, format: "webp")
      placeholder: url(width: 8, height: 8)
    }
    phone {
      id: _id
      title
      phone
      isWhatsapp: is_whatsapp
    }
    email {
      id: _id
      title
      email
    }
  }
  firstCta: first_cta {
    ${ctaFields}
  }
  secondCta: second_cta {
    ${ctaFields}
  }
`;

export default getRecruiterBlockFields;
