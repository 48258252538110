import gql from 'graphql-tag';

import assetFragment from './asset';

const eventItemFragment = gql`
  fragment eventItemFields on EventPage {
    typename: __typename
    id: _id
    title
    slug: _slug
    overviewText: overview_text
    image {
      ...assetFields
      placeholder: url(width: 8)
      url(width: 400, height: 300, format: "webp")
    }
    categories {
      id: _id
      label
    }
    highlightedEvent: highlighted_event
    eventInterval: event_interval
    intervalDay: interval_day
    intervalTiming: interval_timing
    startDate: start_date
    endDate: end_date
    startTime: start_time
    endTime: end_time
    location {
      title
    }
  }
  ${assetFragment}
`;

export default eventItemFragment;
