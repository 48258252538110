import { seoTypes } from '@lib/proptypes';
import Head from 'next/head';
import PropTypes from 'prop-types';

function Seo({
  title,
  suffix = 'Vitalis',
  metaTitle,
  metaDescription,
  ogImage,
}) {
  const pageTitle =
    metaTitle || [title, suffix].filter(part => !!part).join(' - ');

  return (
    <Head>
      {/* HTML Meta Tags */}
      <title>{pageTitle}</title>

      {(metaDescription || metaTitle || ogImage) && (
        <>
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
        </>
      )}
      {metaDescription && (
        <>
          <meta name="description" content={metaDescription} />
          <meta property="og:description" content={metaDescription} />
        </>
      )}
      {metaTitle && <meta property="og:title" content={metaTitle} />}
      {ogImage?.url && <meta property="og:image" content={ogImage.url} />}
    </Head>
  );
}

Seo.propTypes = {
  title: PropTypes.string,
  suffix: PropTypes.string,
  ...seoTypes,
};

export default Seo;
