import gql from 'graphql-tag';

import { seoFields } from './seo';

const jobAlertPageFragment = gql`
  fragment jobAlertFields on JobAlertPage {
    typename: __typename
    id: _id
    title
    introText: intro_text
    flashMessage: flash_message
    ${seoFields}
  }
`;

export default jobAlertPageFragment;
