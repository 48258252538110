function setUrlSearchParams({
  router,
  params,
  scroll = false,
  shallow = true,
}) {
  // Construct query params from object.
  const query = Object.fromEntries(
    Object.entries(params)
      // eslint-disable-next-line no-unused-vars
      .filter(([_, value]) => value != null)
      .map(([key, value]) => [
        key,
        typeof value === 'object' ? value.join(',') : value,
      ])
  );
  // Using .replace over .push because we dont want every filter change in the history
  router.replace(
    {
      pathname: router.pathname,
      query,
    },
    undefined,
    { scroll, shallow }
  );
}

export default setUrlSearchParams;
