import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  slugifiedTitle: PropTypes.string.isRequired,
  minHoursPerWeek: PropTypes.number,
  maxHoursPerWeek: PropTypes.number,
  aggregationChildren: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  educationLevel: PropTypes.shape({
    name: PropTypes.string,
  }),
  minSalaryIndication: PropTypes.string,
  maxSalaryIndication: PropTypes.string,
});
