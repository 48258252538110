import { assetFragment } from '@lib/fragments';
import {
  corporateSectionsFields,
  corporateSectionsFragment,
} from '@lib/fragments/sections';
import { seoFields } from '@lib/fragments/seo';
import gql from 'graphql-tag';

const CORPORATE_HOME_PAGE_QUERY = gql`
  query CORPORATE_HOME_PAGE_QUERY($locale: String) {
    HomePage(locale: $locale) {
      typename: __typename
      id: _id
      title
      subtitle
      introText: intro_text
      image {
        url(width: 975, height: 645, format: "webp")
        placeholder: url(width: 8)
        ...assetFields
      }
      highlightedPages: highlighted_pages { 
        __typename
        ... on ContentPage {
          slug: _slug
          title
          text: overview_text
          image: overview_image {
            url(width: 975, height: 645, format: "webp")
            placeholder: url(width: 8)
            ...assetFields
          }
        }
      }
      ${seoFields}
      ${corporateSectionsFields}
    }
  }
  ${assetFragment}
  ${corporateSectionsFragment}
`;

export default CORPORATE_HOME_PAGE_QUERY;
