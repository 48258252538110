import gql from 'graphql-tag';

import assetFragment from './asset';

const newsItemFragment = gql`
  fragment newsOverviewFields on NewsPage {
    typename: __typename
    id: _id
    title
    slug: _slug
    image {
      ...assetFields
      placeholder: url(width: 8, format: "webp")
      url(width: 1496)
    }
  }
  ${assetFragment}
`;

export default newsItemFragment;
