import { MAXIMUM_RELATED_ITEMS } from '@constants';

export default function parseRelatedNews({
  sameCategoryNews,
  upcomingNews,
  selection = [],
}) {
  if (sameCategoryNews?.items?.length) {
    return {
      items: sameCategoryNews.items.slice(0, MAXIMUM_RELATED_ITEMS),
    };
  }

  const items = selection
    /* Add news based on category */
    .concat(sameCategoryNews?.items || [])
    // Add latest news
    .concat(upcomingNews?.items || []);

  return {
    // make sure only max items are returned
    items: items.slice(0, MAXIMUM_RELATED_ITEMS),
  };
}
