import sort from '@config/matchmaker/sort';
import objectMap from '@utils/objectMap';
import { useMemo } from 'react';

export default function useSortedFilters(filters) {
  return useMemo(
    () =>
      objectMap(filters, (values, key) => {
        const clonedValues = [...values];

        if (!sort[key]) {
          return clonedValues;
        }

        return clonedValues.sort((a, b) => {
          const indexA = sort[key].indexOf(a.code);
          const indexB = sort[key].indexOf(b.code);

          // If both codes are in the orderArray, compare their positions
          if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
          }

          // If only one of them is in the orderArray, prioritize it
          if (indexA !== -1) {
            return -1;
          }
          if (indexB !== -1) {
            return 1;
          }

          // If none of them are in the orderArray, maintain the original order
          return 0;
        });
      }),
    [filters]
  );
}
