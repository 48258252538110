export { default as AGENDA_IFRAME_QUERY } from './agendaIframe';
export { default as AGENDA_OVERVIEW_PAGE_QUERY } from './agendaOverviewPage';
export { default as ALL_SLUGS_QUERY } from './allSlugs';
export { default as getAllSlugsQuery } from './allSlugs';
export {
  getFooterQuery,
  getMainMenuQuery,
  QUICK_MENU_ITEMS_QUERY,
} from './config';
export { default as getContentPageQuery } from './contentPage';
export { default as CONTENT_PAGES_SLUGS_QUERY } from './contentPagesSlugs';
export { default as CORPORATE_HOME_PAGE_QUERY } from './corporateHomePage';
export { default as getEventPageQuery } from './eventPage';
export { default as EVENT_PAGES_QUERY } from './eventPages';
export { default as FIELD_OF_WORK_QUERY } from './fieldOfWork';
export { default as JOB_ALERT_PAGE_QUERY } from './jobAlertPage';
export { default as JOBS_HOME_PAGE_QUERY } from './jobsHomePage';
export { default as LOCATION_DETAILS_QUERY } from './locationDetails';
export { default as LOCATION_OVERVIEW_PAGE_QUERY } from './locationOverviewPage';
export { default as getlocationPageQuery } from './locationPage';
export { default as LOCATION_PAGES_QUERY } from './locationPages';
export { default as MATCHMAKER_PAGE_QUERY } from './matchmakerPage';
export { default as MATCHMAKER_RESULTS_PAGE_QUERY } from './matchmakerResultsPage';
export { default as NEWS_OVERVIEW_PAGE_QUERY } from './newsOverviewPage';
export { default as getNewsPageQuery } from './newsPage';
export { default as NEWS_PAGES_QUERY } from './newsPages';
export { default as NEWS_PAGES_SLUGS_QUERY } from './newsPagesSlugs';
export { default as REFERRAL_PAGE_QUERY } from './referralPage';
export { default as REFERRAL_THANKS_PAGE_QUERY } from './referralThanksPage';
export { default as RELATED_EVENTS_QUERY } from './relatedEvents';
export { default as RELATED_NEWS_QUERY } from './relatedNews';
export { default as RELATED_STORIES_QUERY } from './relatedStories';
export { default as STORY_OVERVIEW_PAGE_QUERY } from './storyOverviewPage';
export { default as STORY_PAGE_QUERY } from './storyPage';
export { default as STORY_PAGES_QUERY } from './storyPages';
export { default as VACANCY_OVERVIEW_PAGE_QUERY } from './vacancyOverviewPage';
export { default as VACANCY_THANKS_PAGE_QUERY } from './vacancyThanksPage';
