import { assetFragment, storyItemFragment } from '@lib/fragments';
import gql from 'graphql-tag';

const getStoriesSectionFragment = ({ ctaFields }) => gql`
  fragment storiesSectionFields on StoriesSection {
    typename: __typename
    id: _id
    title
    text
    stories: stories {
      ...storyItemFields
    }
    fieldsOfWork: fields_of_work {
      id: _id
      label: title
    }
    cta {
      ${ctaFields}
    }
  }
  ${assetFragment}
  ${storyItemFragment}
`;

export default getStoriesSectionFragment;
