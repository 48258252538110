import { MAXIMUM_RELATED_ITEMS } from '@constants';

export default function useRelatedStories(relatedStories) {
  const relatedItems = relatedStories?.sameFieldsOfWorkStories?.items || [];

  if (relatedItems >= MAXIMUM_RELATED_ITEMS) {
    return { items: relatedItems };
  }

  const upcomingStories = (relatedStories?.upcomingStories?.items || []).filter(
    item => !relatedItems.some(relatedItem => relatedItem.id === item.id)
  );

  return {
    items: relatedItems
      .concat(upcomingStories)
      .splice(0, MAXIMUM_RELATED_ITEMS),
  };
}
