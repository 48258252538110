import gql from 'graphql-tag';

const getContactTilesSectionFragment = ({ ctaFields }) => gql`
  fragment contactTilesSectionFields on ContactTilesSection {
    typename: __typename
    id: _id
    tiles {
      id: _id
      title
      text
      phone {
        id: _id
        title
        phone
        isWhatsapp: is_whatsapp
      }
      email {
        id: _id
        title
        email
      }
      cta {
        ${ctaFields}
      }
    }
    backgroundColor: background_color {
      color
    }
  }
`;

export default getContactTilesSectionFragment;
