import { corporateCtaFields, jobsCtaFields } from '@lib/fragments/cta';
import bannerSectionFragment from '@organisms/BannerSection/banner-section-fragment';
import getContactSectionFragment from '@organisms/ContactSection/contact-section-fragment';
import getContactTilesSectionFragment from '@organisms/ContactTilesSection/contact-tiles-section-fragment';
import getDownloadSectionFragment from '@organisms/DownloadSection/download-section-fragment';
import eventsSectionFragment from '@organisms/EventsSection/events-section-fragment';
import getFaqSectionFragment from '@organisms/FaqSection/faq-section-fragment';
import getImageSectionFragment from '@organisms/ImageSection/image-section-fragment';
import getImageSliderSectionFragment from '@organisms/ImageSliderSection/image-slider-section.fragment';
import getJobAlertSectionFragment from '@organisms/JobAlertSection/job-alert-section-fragment';
import getRelatedNewsSectionFragment from '@organisms/RelatedNewsSection/related-news-section-fragment';
import getStoriesSectionFragment from '@organisms/StoriesSection/stories-section-fragment';
import getTextImageSectionFragment from '@organisms/TextImageSection/text-image-section-fragment';
import getTextSectionFragment from '@organisms/TextSection/text-section-fragment';
import getTextVideoSectionFragment from '@organisms/TextVideoSection/text-video-section-fragment';
import typeformSectionFragment from '@organisms/TypeformSection/typeform-section-fragment';
import getVideoSectionFragment from '@organisms/VideoSection/video-section-fragment';
import gql, { disableFragmentWarnings } from 'graphql-tag';

// We need to suppress the gql warning here because of the logic on line 90
disableFragmentWarnings();

export const sharedSectionsFields = `
  ... on TextSection {
    ...textSectionFields
  }
  ... on ImageSection {
    ...imageSectionFields
  }
  ... on VideoSection {
    ...videoSectionFields
  }
  ... on TextImageSection {
    ...textImageSectionFields
  }
  ... on TextVideoSection {
    ...textVideoSectionFields
  }
  ... on BannerSection {
    ...bannerSectionFields
  }
  ... on FaqSection {
    ...faqSectionFields
  }
  ... on RelatedNewsSection {
    ...relatedNewsSectionFields
  }
  ... on EventsSection {
    ...eventsSectionFields
  }
  ... on DownloadSection {
    ...downloadSectionFields
  }
  ... on TypeformSection {
    ...typeformSectionFields
  }
  ... on ImageSliderSection {
    ...imageSliderSectionFields
  }
  ... on ContactSection {
    ...contactSectionFields
  }
  ... on ContactTilesSection {
    ...contactTilesSectionFields
  }
`;

export const corporateSectionsFields = `
  sections {
    ${sharedSectionsFields}
  }
`;

export const jobsSectionsFields = `
  sections {
    ... on JobAlertSection {
      ...jobAlertSectionFields
    }
    ... on StoriesSection {
      ...storiesSectionFields
    }    
    ${sharedSectionsFields}
  }
`;

export const corporateSectionsFragment = gql`
  ${bannerSectionFragment}
  ${eventsSectionFragment}
  ${typeformSectionFragment}
  ${getContactSectionFragment({ ctaFields: corporateCtaFields })}
  ${getContactTilesSectionFragment({ ctaFields: corporateCtaFields })}
  ${getTextSectionFragment({ ctaFields: corporateCtaFields })}
  ${getImageSectionFragment({ ctaFields: corporateCtaFields })}
  ${getVideoSectionFragment({ ctaFields: corporateCtaFields })}
  ${getTextImageSectionFragment({ ctaFields: corporateCtaFields })}
  ${getTextVideoSectionFragment({ ctaFields: corporateCtaFields })}
  ${getDownloadSectionFragment({ ctaFields: corporateCtaFields })}
  ${getFaqSectionFragment({ ctaFields: corporateCtaFields })}
  ${getRelatedNewsSectionFragment({ ctaFields: corporateCtaFields })}
  ${getImageSliderSectionFragment({ ctaFields: corporateCtaFields })}
`;

export const jobsSectionsFragment = gql`
  ${bannerSectionFragment}
  ${eventsSectionFragment}
  ${typeformSectionFragment}
  ${getContactSectionFragment({ ctaFields: jobsCtaFields })}
  ${getContactTilesSectionFragment({ ctaFields: jobsCtaFields })}
  ${getTextSectionFragment({ ctaFields: jobsCtaFields })}
  ${getImageSectionFragment({ ctaFields: jobsCtaFields })}
  ${getVideoSectionFragment({ ctaFields: jobsCtaFields })}
  ${getTextImageSectionFragment({ ctaFields: jobsCtaFields })}
  ${getTextVideoSectionFragment({ ctaFields: jobsCtaFields })}
  ${getFaqSectionFragment({ ctaFields: jobsCtaFields })}
  ${getRelatedNewsSectionFragment({ ctaFields: jobsCtaFields })}
  ${getJobAlertSectionFragment({ ctaFields: jobsCtaFields })}
  ${getStoriesSectionFragment({ ctaFields: jobsCtaFields })}
  ${getImageSliderSectionFragment({ ctaFields: jobsCtaFields })}
  ${getDownloadSectionFragment({ ctaFields: jobsCtaFields })}
`;

export const getSectionsFields = ({ isCorporateDomain }) =>
  isCorporateDomain ? corporateSectionsFields : jobsSectionsFields;

export const getSectionsFragments = ({ isCorporateDomain }) =>
  isCorporateDomain ? corporateSectionsFragment : jobsSectionsFragment;
