import {
  corporateCtaFields,
  corporateInternalLinkFields,
  corporateMenuItemFragment,
  jobsCtaFields,
  jobsInternalLinkFields,
  jobsMenuItemFragment,
} from '@lib/fragments';
import gql from 'graphql-tag';

export const QUICK_MENU_ITEMS_QUERY = gql`
  query QUICK_MENU_ITEMS_QUERY {
    HomePage {
      quickMenuItems: quick_menu_items {
        title
        icon {
          icon
        }
        internalLink: page {
          ... on Model {
            slug: _slug
          }
        }
        externalLink: external_link
      }
    }
  }
`;

export const getFooterQuery = ({ isCorporateDomain }) => gql`
  query FOOTER_QUERY {
    Footer {
      typename: __typename
      navigationTitle: navigation_title
      openingTimes: opening_times
      contactTitle: contact_title
      contactLinks: contact_links {
        ... on ContactPhone {
          id: _id
          title
          phone
          isWhatsapp: is_whatsapp
        }
        ... on ContactEmail {
          id: _id
          title
          email
        }
      }
      navigationMenu: navigation_menu {
        ${
          isCorporateDomain
            ? '...corporateMenuItemFields'
            : '...jobsMenuItemFields'
        }
      }
      bottomLinks: bottom_links {
        id: _id
        label
        internalLink: internal_link {
          ${
            isCorporateDomain
              ? corporateInternalLinkFields
              : jobsInternalLinkFields
          }
        }
        externalLink: external_link
      }
      seniorTitle: senior_title
      seniorText: senior_text
      socialsTitle: socials_title
      seniorLinks: senior_links {
        ... on ContactPhone {
          id: _id
          title
          phone
          isWhatsapp: is_whatsapp
        }
        ... on ContactEmail {
          id: _id
          title
          email
        }
        ... on ContactLink {
          id: _id
          title
          url {
            ${isCorporateDomain ? corporateCtaFields : jobsCtaFields}
          }
        }
      }
    }
  }
  ${isCorporateDomain ? corporateMenuItemFragment : jobsMenuItemFragment}
`;

export const getMainMenuQuery = ({ isCorporateDomain }) => gql`
  query MAIN_MENU_QUERY {
    MainMenu {
      navigationMenu: navigation_menu {
        ${
          isCorporateDomain
            ? '...corporateMenuItemFields'
            : '...jobsMenuItemFields'
        }
      }
    }
  }
  ${isCorporateDomain ? corporateMenuItemFragment : jobsMenuItemFragment}
`;
