import classNames from 'classnames';
import PropTypes from 'prop-types';
import showdown from 'showdown';

import styles from './RichText.module.scss';

function RichText({ text, isMarkdown, className = '' }) {
  if (isMarkdown) {
    const converter = new showdown.Converter();
    converter.setOption('strikethrough', true);
    // Add custom markdown for underlined text
    const markdownText = text
      .replace(/\+\+([^++]+)\+\+/g, '<u>$1</u>')
      .replaceAll('<br><br>-', '\n\n-')
      .replaceAll('<br>-', '\n-');
    return (
      <div
        className={classNames(styles.content, className)}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: converter.makeHtml(markdownText) }}
      />
    );
  }

  return (
    <div
      className={classNames(styles.content, className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: text.replaceAll('\r', '<br>') }}
    />
  );
}

RichText.propTypes = {
  className: PropTypes.string,
  isMarkdown: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default RichText;
