import { useState } from 'react';

export default function useDebounce(callback, delay = 300) {
  const [timeoutId, setTimeoutId] = useState();

  const debounce = (...args) => {
    setTimeoutId(clearTimeout(timeoutId));

    setTimeoutId(
      setTimeout(() => {
        callback(...args);
      }, delay)
    );
  };

  return debounce;
}
