import PropTypes from 'prop-types';

const vacancyFilterType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});

export default PropTypes.shape({
  applicationForms: PropTypes.arrayOf(vacancyFilterType),
  functionCategories: PropTypes.arrayOf(vacancyFilterType),
  subjectAreas: PropTypes.arrayOf(vacancyFilterType),
  shiftTypes: PropTypes.arrayOf(vacancyFilterType),
  locations: PropTypes.arrayOf(vacancyFilterType),
});
