import gql from 'graphql-tag';

const getVideoSectionFragment = ({ ctaFields }) => gql`
  fragment videoSectionFields on VideoSection {
    typename: __typename
    id: _id
    title
    text
    cta {
      ${ctaFields}
    }
    video {
      embeddedVideo: embedded_video {
        playbackId: playback_id
      }
      youtubeId: youtube_id
      alt: alt_text
      thumbnail {
        ...assetFields
        url(width: 1280, height: 720, format: "webp")
        placeholder: url(width: 8)
      }
    }
    backgroundColor: background_color {
      color
    }
  }
`;

export default getVideoSectionFragment;
