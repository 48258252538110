import {
  AFTERNOON_SHIFT_KEY,
  EVENING_SHIFT_KEY,
  LONG_SHIFT_KEY,
  MORNING_SHIFT_KEY,
  NIGHT_SHIFT_KEY,
  SHORT_SHIFT_KEY,
  WEEKEND_SHIFT_KEY,
} from '@constants';

const shiftTypes = [
  { title: 'Ochtend', key: MORNING_SHIFT_KEY },
  { title: 'Middag', key: AFTERNOON_SHIFT_KEY },
  { title: 'Avond', key: EVENING_SHIFT_KEY },
  { title: 'Nacht', key: NIGHT_SHIFT_KEY },
  { title: 'Weekend', key: WEEKEND_SHIFT_KEY },
  { title: 'Kort', key: SHORT_SHIFT_KEY },
  { title: 'Lang', key: LONG_SHIFT_KEY },
];

export default shiftTypes;
