import { SCROLL_OFFSET } from '@constants';

function scrollTo({ scrollToRef, scrollOffstet = SCROLL_OFFSET }) {
  const topOfElement =
    (scrollToRef?.current?.getBoundingClientRect().top || 0) + window.scrollY;
  return window.scrollTo({
    top: topOfElement - scrollOffstet,
    behavior: 'smooth',
  });
}

export default scrollTo;
