import gql from 'graphql-tag';

import assetFragment from './asset';

const parentPageFragment = gql`
  fragment parentPageFields on ContentPage {
    typename: __typename
    slug: _slug
    title
  }
`;

const contentPageFragment = gql`
  fragment contentPageFields on ContentPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    metaTitle: meta_title
    metaDescription: meta_description
    ogImage: og_image {
      ...assetFields
      url(width: 1280, format: "webp")
    }
    parentPage: parent_page {
      ...parentPageFields
      parentPage: parent_page {
        ...parentPageFields
        parentPage: parent_page {
          ...parentPageFields
        }
      }
    }
    hideNavigationMenu: hide_navigation_menu
  }
  ${assetFragment}
  ${parentPageFragment}
`;

export default contentPageFragment;
