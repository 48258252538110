/* eslint-disable react/prop-types */
import algoliasearch from 'algoliasearch/lite';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { isCorporateDomain, isJobsDomain } from './getDomain';

const StoreContext = createContext({});

const searchClient = algoliasearch(
  'IPLX16EF1U',
  '6e3c8ae6a962c43cc546e8b81f477e6e'
);

// Export the provider as we need to wrap the entire app with it
export function StoreProvider({ store: storeProps, children }) {
  const corporateDomain = isCorporateDomain(storeProps.host);
  const getSearchIndex = () => {
    switch (process.env.NEXT_PUBLIC_ENV) {
      case 'dev':
        return corporateDomain ? 'site_dev' : 'werkenbij_dev';
      case 'test':
        return corporateDomain ? 'site_test' : 'werkenbij_test';
      case 'accept':
        return corporateDomain ? 'site_accept' : 'werkenbij_accept';
      default:
        return corporateDomain ? 'site' : 'werkenbij';
    }
  };
  const searchIndex = getSearchIndex();
  const [store, setStore] = useState({
    ...storeProps,
    searchClient,
    searchIndex,
    isJobsDomain: isJobsDomain(storeProps.host),
    isCorporateDomain: isCorporateDomain(storeProps.host),
  });

  useEffect(() => {
    setStore({
      ...storeProps,
      searchClient,
      searchIndex,
      isJobsDomain: isJobsDomain(storeProps.host),
      isCorporateDomain: corporateDomain,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeProps.preview, storeProps.page]);

  // Make the provider update only when it should.
  // We only want to force re-renders if the store state changes.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly!
  // we want to keep things very performant.
  const memoedValue = useMemo(() => ({ store, setStore }), [store, setStore]);
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <StoreContext.Provider value={memoedValue}>
      {children}
    </StoreContext.Provider>
  );
}

// Let's only export the `useStore` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useStore() {
  return useContext(StoreContext);
}
