import Seo from '@atoms/Seo/Seo';
import { seoTypes } from '@lib/proptypes';
import AccessibilityPanel from '@molecules/AccessibilityPanel/AccessibilityPanel';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import Footer from '@organisms/Footer/Footer';
import Header from '@organisms/Header/Header';
import QuickLinksModal from '@organisms/QuickLinksModal/QuickLinksModal';
import useStore from '@utils/useStore';
import PropTypes from 'prop-types';

function LayoutBase({
  children,
  title,
  suffix,
  metaTitle,
  metaDescription,
  ogImage,
}) {
  const {
    store: { isCorporateDomain },
  } = useStore();

  return (
    <>
      <Seo
        title={title}
        suffix={suffix}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        ogImage={ogImage}
      />

      <Header />
      <Breadcrumbs />
      <main>{children}</main>
      <Footer />
      {isCorporateDomain && <QuickLinksModal />}
      <AccessibilityPanel />
    </>
  );
}

LayoutBase.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  suffix: PropTypes.string,
  ...seoTypes,
};

export default LayoutBase;
